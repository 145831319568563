<template>
  <div class="mainDiv">
    <div id="rows">
      <div v-for="(value, index) in documentHistory" :key="index" class="row">
        <div class="icon">
          <VasionSenderIcon v-if="value.actionTaken === 'Sent'" />
          <VasionSignerIcon v-if="value.actionTaken === 'Signed'" />
          <VasionViewedIcon v-if="value.actionTaken === 'Viewed'" />
          <VasionVoidIcon v-if="value.actionTaken === 'Recalled'" />
          <VasionSignedCompletedIcon v-if="value.actionTaken === 'SignedCompleted'" />
          <VasionCompletedIcon v-if="value.actionTaken === 'Completed'" />
          <VasionRejectedIcon v-if="value.actionTaken === 'Rejected'" />
          <VasionCommentIcon v-if="value.actionTaken === 'Comment'" />
        </div>
        <div id="date" class="margin width20">
          <div class="fontWeight">
            {{ getDate(value.insertDate) }}
          </div>
          <div class="grey400 fontWeight">
            {{ getTime(value.insertDate) }}
          </div>
        </div>
        <div id="user-info" class="margin width20">
          <div class="fontWeight">
            {{ getFullName(value) }}
          </div>
          <div class="grey400 fontWeight">
            {{ getIpAndGeoLocation(value) }}
          </div>
        </div>
        <div v-if="value.actionTaken !== 'Comment'" id="comment" class="width40">
          <div>{{ value.actionDescription }}</div>
        </div>
        <div v-else id="comment" class="width40">
          <div>{{ value.comment }}</div>
        </div>
      </div>
    </div>
    <div id="buttons">
      <VasionButton id="export-to-pdf" :classProp="'secondary-grey'" @vasionButtonClicked="$emit('exportToPdf')">
        Export to PDF
      </VasionButton>
      <VasionButton id="close" :classProp="'secondary-grey'" @vasionButtonClicked="$emit('close')">
        Close
      </VasionButton>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'DocumentSignatureHistory',
  props: {
    documentHistory: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      ocrAllPages: false,
    }
  },
  methods: {
    getDate(date) {
     const newDate = Date.parse(date)
     return format(newDate, 'MMMM dd yyyy')
    },
    getFullName(value) {
      return `${value.firstName} ${value.lastName}`
    },
    getIpAndGeoLocation(value) {
      return `IP: ${value.ipAddress} \n ${value.geolocation}`
    },
    getTime(time) {
      const newDate = new Date(time).toLocaleTimeString()
      return `${newDate}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

#buttons {
  display: flex;
  justify-content: flex-end;
}
.mainDiv {
  width: 700px;
  height: 630px;
}
.width20 {
  width: 20%;
}
.row{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0px;
  flex-wrap: wrap;
  padding-top: 12px;
  padding-bottom: 12px;
  align-items: center;
}
#comment {
  color: black;
  font-weight: 500;
}
.icon {
  fill: $grey-400;
  margin: 0 16px;
  display: flex;
  align-items: center;
}
#rows {
  height: 580px;
  overflow-y: auto;
}
.grey400 {
  color: $grey-400;
}
.fontWeight {
  font-weight: 500;
}
.margin {
  margin: 0px 16px;
}
.margin > div {
  margin-bottom: 8px;
}
.width40 {
  width: 40%;
}
</style>
