<template>
  <div id="document-versions">
    <md-dialog id="versionsModal" class="wrapper" :md-active.sync="showModal">
      <div v-if="versions && versions.tableHeaders && versions.tableRows.length > 0" class="results-grid-div">
        <div>
          <VasionTable 
            :headerColumns="versions.tableHeaders" 
            :tableRows="versions.tableRows" 
            :ellipsisButtonConfig="ellipsisButtonConfig"
            @ellipsis-button-clicked="ellipsisButtonClicked"
            :ellipsisOffset="-200"
          />
        </div>
      </div>
      <div v-else class="no-results-div">
        <label>No Versions available for this Document</label>
      </div>
      <div class="versions-bottom-div">
        <VasionButton
          v-if="isReadOnly"
          id="originalVersion"
          :classProp="'primary'"
          @vasionButtonClicked="toOriginalVersion()"
        >
          Current Version
        </VasionButton>
        <VasionButton id="close" :classProp="'primary'" @vasionButtonClicked="showModal = !showModal">
          Close
        </VasionButton>
      </div>
    </md-dialog>

    <md-dialog id="vasion-confirmation-div" :md-active.sync="showConfirmation">
      <VasionConfirmationDialog :message="confirmationMessage" @noButtonClick="showConfirmation=false" @yesButtonClick="confirmationConfirmed()" />
    </md-dialog>

    <VasionSnackbar
      id="document-versions-snack"
      :closeOnClick="true"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
export default {
  name: 'TheDocumentVersions',
  props: {
    active: {
      type: Boolean,
      default: true,
      required: false,
    },
    documentId: {
      type: Number,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  data: function () {
    return {
      confirmationMessage: '',
      ellipsisButtonConfig: {
        buttons: [
          {
            id: 'open',
            name: 'Open',
            icon: 'VasionArrowRightIcon'
          },
          {
            id: 'rollback',
            name: 'Rollback Version',
            icon: 'VasionHistoryIcon'
          },
        ],
      },
      selectedVersion: 'none',
      showConfirmation: false,
      showModal: this.active,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  computed: {
    versions() { 
      return this.$store.state.document.documentVersionData 
    },
  },
  watch: {
    active: function () {
      this.showModal = this.active
    },
    showModal: function () {
      if (!this.showModal) {
        this.$emit('close')
      }
    },
  },
  methods: {
    async confirmationConfirmed() {
      const response = await this.$store.dispatch('document/rollbackVersion', { documentID: this.documentId, versionNumber: this.selectedVersion })
      this.showConfirmation = false

      if (response.Versions === null)
      {
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = 'An error occurred while rolling back to the selected version.'
        this.snackbarImage = false
        this.showSnackbarBool = true
        return        
      }

      this.$store.dispatch('document/getDocumentVersions', this.documentId)
      this.showModal = !this.showModal
      this.toOriginalVersion()
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        this.selectedVersion = payload.item.Values[3]
        switch (payload.buttonId) {
          case 'open':
            this.openVersion()
            break;
          case 'rollback':
            this.rollbackVersion()
            break;
          default:
            break;
        }
      }
    },
    async openVersion() {
      const data = await this.$store.dispatch('document/getDocumentDetailsWithVersion', { documentID: this.documentId, version: this.selectedVersion })
      this.$emit('versionSelect', { data: data })
      this.$store.dispatch('document/setBackRoute', `/document/${this.documentId}`)
      this.$router.push({ name: 'DocumentWithVersion', params: { documentID: this.documentId.toString(), version: this.selectedVersion } })
      this.showModal = !this.showModal
    },
    rollbackVersion() {
      this.confirmationMessage = `Are you sure you want to roll back to version "${this.selectedVersion}"?`
      this.showConfirmation = true
    },
    toOriginalVersion() {
      this.$router.push({ name: 'Document', params: { documentID: this.documentId } })
    },
  },
}
</script>

<style lang="scss" scoped>
  .results-grid-div {
    padding: 10px 10px 0px 10px;
    height: 450px;
    overflow: auto;
  }

  .no-results-div {
    padding: 10px 10px 0px 10px;
    height: 90px;
  }

  .versions-bottom-div {
    position: absolute;
    bottom: 0px;
    width: calc(100% - 5px);
    text-align: right;
    vertical-align: middle;
    line-height: 50px;
  }

  .wrapper {
    width: 50%;
  }
</style>
