<template>
  <div>
    <VasionGeneralModal
      :rejectButtonText="'CLOSE'"
      :sync="showModal"
      :modalType="'slot'"
      :showConfirmButton="false"
      @noButtonClick="showModal = !showModal"
    >
      <h3>Line Items</h3>
      <div v-if="Array.isArray(lineItems) && lineItems.length">
        <VasionDropList
          id="line-item-list"
          v-slot="slotItem"
          v-model="selectedLineItemList"
          :dataArray="lineItemList"
          title="Select Line Item List"
          inputType="plain-list"
          valueName="name"
          displayName="name"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
      <div v-if="dataAvailable" class="lineItemsGridDiv">
        <VasionTable :headerColumns="dataTable.Columns.Values" :tableRows="dataTable.Rows.Values" :supportSorting="supportSorting" />
      </div>
      <div v-else class="no-data">
        No line items available for this document or selected list.
      </div>
    </VasionGeneralModal>
  </div>
</template>

<script>
export default {
  name: 'TheDocumentLineItems',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    documentId: {
      type: Number,
      required: true,
    },
    supportSorting: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: function () {
    return {
      dataTable: '',
      lineItemList: [{ name: '', value: '' }],
      selectedLineItemList: '',
      showModal: this.active,
    }
  },
  computed: {
    dataAvailable() {
      return this.dataTable
        && this.dataTable.Columns
        && this.dataTable.Columns.Values
        && this.dataTable.Rows
        && this.dataTable.Rows.Values
        && this.dataTable.Rows.Values.length > 0
    },
    lineItems() { return this.$store.state.document.lineItems },
  },
  watch: {
    active: function () {
      this.showModal = this.active
    },
    documentId: function () {
      this.reloadData()
    },
    selectedLineItemList() {
      this.setDataTable()
    },
    showModal: function () {
      if (!this.showModal) {
        this.$emit('close')
      }
    },
  },
  async created() {
    if (this.documentId > 0) {
      await this.reloadData()
    }
  },
  methods: {
    async reloadData() {
      const payload = {
        Value: this.documentId,
      }
      await this.$store.dispatch('document/getDocumentLineItems', payload)
      await this.setLineItemList()
      if (this.lineItemList && this.lineItemList.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedLineItemList = this.lineItemList[0]
      }
    },
    setDataTable() {
      let data = this.lineItems || [{ tableData: [] }]
      if (this.selectedLineItemList && this.selectedLineItemList !== '') {
        data = data.find(d => d.tableName === this.selectedLineItemList.value) ? data.find(d => d.tableName === this.selectedLineItemList.value) : { name: '', value: '' }
      }
      this.dataTable = data.tableData
    },
    setLineItemList() {
      let list = [{ name: '', value: '' }]
      if (this.lineItems && this.lineItems.length > 0) {
        list = this.lineItems.map(d => {
          return {
            name: d.tableName,
            value: d.tableName,
          }
        })
      }
      this.lineItemList = list
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .no-data {
    margin: 5px 0px;
  }

  .alignRight {
    text-align: right;
  }

  .lineItemsGridDiv {
    padding: 10px 10px 0px 10px;
    height: 80vh;
    width: 70vw;
  }

  @media(max-width: $phone){
    .lineItemsGridDiv {
      min-width: 300px;
    }
  }

  .lineItemsFieldsDiv {
    padding: 10px 10px 0px 10px;
    width: 800px;
  }
</style>,
