<template>
  <div class="main-div">
    <div class="md-layout top-div">
      <VasionInput
        v-show="!showPassword"
        id="hidden-password-input"
        v-model="password"
        inputType="top-white"
        class="input-style"
        type="password"
        :width="'300'"
        :title="inputTitle"
        :isInErrorState="passwordError"
      />
      <VasionInput
        v-show="showPassword"
        id="plain-password-input"
        v-model="password"
        inputType="top-white"
        class="input-style"
        :width="'300'"
        :title="inputTitle"
        :isInErrorState="passwordError"
      />
      <VasionButton id="show-hide-password" :classProp="'primary-light'" @vasionButtonClicked="showPassword = !showPassword">
        {{ showHidePasswordText }}
      </VasionButton>
    </div>
    <div class="bottom-div">
      <VasionButton id="cancel-button" :classProp="'primary-light'" @vasionButtonClicked="closePrompt()">
        CANCEL
      </VasionButton>
      <VasionButton
        id="ok-button"
        :classProp="'primary'"
        :isDisabled="disableOKButton"
        @vasionButtonClicked="okClick()"
      >
        OK
      </VasionButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordPrompt',
  props: {
    defaultPasswordProp: {
      type: String,
      required: false,
      default: '',
    },
    inputTitle: {
      type: String,
      required: false,
      default: 'Password',
    },
    requirePasswordEntry: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: function () {
    return {
      miscKey: 'ChangePasswordValue',
      password: this.defaultPasswordProp,
      passwordError: false,
      showPassword: false,
    }
  },
  computed: {
    disableOKButton() { return this.requirePasswordEntry && this.password === '' },
    showHidePasswordText() { return this.showPassword ? 'Hide' : 'Show' },
  },
  methods: {
    closePrompt() {
      this.$emit('close')
    },
    okClick() {
      this.$emit('passwordEntered', this.password)
    },
    setErrorClass() {
      this.passwordError = true
    },
  },
}
</script>

<style lang="scss" scoped>

  .main-div {
    width: 100%;
    height: 100%;
    margin: 32px 24px 32px 24px;
  }

  #show-hide-password{
    padding-top: 17px;
  }

  .top-div {
    height: 120px;
    width: 405px;
  }

  .bottom-div {
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);
    text-align: right;
    vertical-align: middle;
    line-height: 50px;
  }

  .alignRight {
    text-align: right;
  }

</style>
