<template>
  <div id="document-history">
    <div v-if="mode === 'history' && history && history.tableHeaders" class="results-grid-div">
      <div v-if="history.tableRows.length > 0">
        <VasionTable :headerColumns="history.tableHeaders" :tableRows="history.tableRows" />
      </div>
      <div v-else>
        No history available for this document
      </div>
    </div>
    <md-table v-if="mode === 'comments'" class="comments-table">
      <md-table-row class="table-row comments-header">
        <md-table-head :class="'vasion-table-head-label'">
          Workflow History
        </md-table-head>
      </md-table-row>
      <md-table-row v-for="(comment, index) in comments" :key="index" :class="{'vasion-lightest-gray-background': index % 2 === 0 }">
        <md-table-cell>{{ comment }}</md-table-cell>
      </md-table-row>
    </md-table>
    <div class="comment-buttons-align-right">
      <div class="left-padding" />
      <VasionButton
        v-if="showExportPDFButton"
        id="export-to-pdf"
        :classProp="'secondary-grey'"
        @vasionButtonClicked="exportPdf()"
      >
        Export to PDF
      </VasionButton>
      <VasionButton
        v-if="showExportCSVButton"
        id="export-to-csv"
        :classProp="'secondary-grey'"
        @vasionButtonClicked="exportCsv()"
      >
        Export to CSV
      </VasionButton>
      <VasionButton id="close" :classProp="'secondary-grey'" @vasionButtonClicked="$emit('close')">
        Close
      </VasionButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheDocumentHistory',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    documentId: {
      type: Number,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    comments: {
      type: Object,
      required: true,
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    canExportDocumentHistory() { return this.$store.getters['common/canExportDocumentHistory'] },
    history() { return this.$store.state.document.history },
    showExportCSVButton() {
      return this.mode === 'history'
        && this.history && this.history.tableRows && this.history.tableRows.length > 0
        && this.canExportDocumentHistory
    },
    showExportPDFButton() {
      if (this.mode === 'history') {
        return this.history && this.history.tableRows && this.history.tableRows.length > 0 && this.canExportDocumentHistory
      }

      return this.comments && this.comments.length > 0
    },
  },
  methods: {
    exportCsv() {
      this.$store.dispatch('document/exportDocumentHistoryCSV', this.documentId)
    },
    exportPdf() {
      if (this.mode === 'history') {
        this.$store.dispatch('document/exportDocumentHistoryPDF', this.documentId)
      } else {
        this.$store.dispatch('document/exportWorkflowHistoryPDF', this.documentId)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .comments-table {
    padding: 10px 10px 0px 10px;
    height: 400px;
    width: 600px;
    overflow-y: auto;
  }

  .comments-header {
    background-color: #e9ebed;
    height: 40px;
    height: 40px;

  }

  .results-grid-div {
    margin: 20px 0px 10px 0px;
    overflow: auto;
    height: 580px;
    min-width: 900px;
  }

  .comment-buttons-align-right {
    display: flex;
    justify-content: right;
    text-align: right;
  }

  .left-padding {
    width: 80%;
  }

  @media (max-width: $phone) {
    #historyModal {
      max-width: 445px;
      width: 95vw;
    }

    .comments-table {
      max-width: 400px;
      width: 90vw;
    }

    .results-grid-div {
      max-width: 330px;
      width: 90vw;
      max-height: 425px;
    }

    .comment-buttons-align-right {
      display: block;
    }

    .left-padding {
      width: 0%;
    }
  }
</style>
