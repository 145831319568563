<template>
  <div class="delete-pages-wrapper">
    <div
      v-for="(page,index) in fileDetail.PageCount"
      :key="index"
      class="pages-card"
      :class="{ 'highlighted-card' : cardIsSelected(page) }"
      @click="selectedPage(page)"
    >
      <div class="image-placeholder">
        <ThumbnailImage
          ref="mainImage"
          :documentID="documentID"
          :pageNumber="page"
          :width="168"
          :index="index"
        />
      </div>
      <div class="card-footer">
        <h5>Page {{ page }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import ThumbnailImage from '@/components/document/ThumbnailImage.vue';

export default {
  name: 'DocumentDeletePages',
  components: {
    ThumbnailImage,
  },
  props: {
    documentID: {
      required: true,
      type: Number,
    },
    fileDetail: {
      type: Object,
      required: false,
      default: null,
    },
    pageNumber: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      highlighted: false,
      selectedPages: [],
    }
  },
  created() {
  },
  methods: {
    cardIsSelected(page) {
      return this.selectedPages.find(e => e === page)
    },
    selectedPage(page) {
      const isFound = this.selectedPages.find(e => e === page)
      if (isFound) {
        this.selectedPages = this.selectedPages.filter(e => e !== page)
      } else {
        this.selectedPages.push(page)
      }

      this.$emit('updateSelectedPages', this.selectedPages)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.delete-pages-wrapper {
  width: 728px;
  height: 428px;
  display: flex;
  flex-wrap: wrap;
}
.pages-card {
  padding-top: 11px;
  width: 208px;
  height: 218px;
  border-radius: 4px;
  border: solid 2px $grey-100;
  position: relative;
  margin: 13px;
  cursor: pointer;
  background-color: $grey-50;
}
.image-placeholder {
  top: 0px;
  width: 100%;
  height: 80%;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.card-footer {
  top: 80%;
  background-color: $white;
  width: 100%;
  height: 20%;
  z-index: 199;
  border-top: solid 2px $grey-100;
  color: black;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.highlighted-card {
  border: 2px solid $orange-100;
  .card-footer {
    background-color: $orange-50;
    border-top: 2px solid $orange-100;
  }
}
</style>
