<template>
  <div class="modal-bounds">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="files-header">
      PAGE
    </div>
    <div class="page">
      <VasionInput
        id="max-views"
        v-model="uploadedPagesPageNumber"
        class="input-style"
        inputType="top-white"
        min="1"
        :max="totalPages"
        name="max-views"
        type="number"
        width="208"
        onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
      />
      <md-radio
        v-model="insertOption"
        class="radio-button-spacing radio-fix"
        value="0"
        @change="newInsertOption"
      >
        Insert Page Before
      </md-radio>
      <md-radio
        v-model="insertOption"
        class="radio-button-spacing radio-fix"
        value="1"
        @change="newInsertOption"
      >
        Insert Page After
      </md-radio>
      <md-radio
        v-model="insertOption"
        class="radio-button-spacing radio-fix"
        value="2"
        @change="newInsertOption"
      >
        Replace Page
      </md-radio>
    </div>
    <div class="files">
      <div class="files-header">
        FILES
      </div>
      <div v-for="(file, index) in uploadedPagesToAdd" :key="index" class="file-group">
        <VasionInput
          id="response-attribute-username"
          v-model="file.fileName"
          inputType="top-white"
          name="response-attribute-username"
          placeholder="Choose File..."
          width="636"
          :readonly="true"
        />
        <VasionButton
          id="select-file"
          class="button-margin-fix"
          :classProp="'primary'"
          @vasionButtonClicked="$emit('add', index)"
        >
          Select
        </VasionButton>
        <VasionButton
          id="delete-file"
          class="button-margin-fix"
          :classProp="'secondary'"
          @vasionButtonClicked="removeFile(index)"
        >
          X Remove
        </VasionButton>
      </div>
    </div>
    <VasionButton
      id="add-file"
      class="add-button"
      :classProp="'secondary'"
      @vasionButtonClicked="addFile"
    >
      Add File
    </VasionButton>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'AddPages',
  components: {
    Loading,
  },
  props: {
    totalPages: {
      type: Number,
      default: 1,
      isRequired: true,
    },
  },
  data: function () {
    return {
      insertOption: this.$store.state.document.uploadedPagesInsertOption,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      showUploadDialog: false,
    }
  },
  computed: {
    readyForUpload() { return this.pagePlacement !== '' && this.uploadedPagesToAdd.length > 0 && this.uploadedPagesToAdd[0].fileName !== '' },
    uploadedPagesPageNumber: {
      get() { return this.$store.state.document.uploadedPagesPageNumber },
      set(newVal) {
        this.$store.dispatch('document/uploadedPagesPageNumber', newVal)
      },
    },
    uploadedPagesToAdd() { return this.$store.state.document.uploadedPagesToAdd },
    uploadNewPagesKey() { return this.$store.state.document.uploadNewPagesKey },
  },
  watch: {
    uploadedPagesInsertOption(newVal) {
      this.insertOption = newVal
    },
    uploadNewPagesKey() {
      this.uploadPage()
    },
  },
  created() {
  },
  methods: {
    addFile() {
      this.$store.dispatch('document/addNewDocumentSlot')
    },
    newInsertOption() {
      this.$store.dispatch('document/uploadedPagesInsertOption', this.insertOption)
    },
    removeFile(index) {
      this.$store.dispatch('document/removePageFromList', index)
    },
    async uploadPage() {
      const payload = {
        documentID: this.$route.params.documentID,
        pageNumber: this.uploadedPagesPageNumber,
        insertOption: this.insertOption,
        newFileList: this.uploadedPagesToAdd,
      }
      const res = await this.$store.dispatch('document/insertReplacePages', payload)
      if (res.data.Value === 'True') {
        this.$emit('success')
      } else {
        this.$emit('failure')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .add-button {
    position: relative;
    right: 7px;
    margin-top: 16px;
  }
  .button-margin-fix {
    align-self: flex-end;
    position: relative;
    top: 3px;
  }
  .files {
    display: flex;
    flex-direction: column;
  }
  .files-header {
    font-family: $font-family-medium;
    color: $grey-400;
    font-size: 12px;
    font-weight: 500;
    margin: 30px 0 5px 0;
  }
  .file-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 16px 0;
  }
  .modal-bounds {
    width: 928px;
    height: 628px;
    background-color: $white;
  }
  .page {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .radio-fix {
    margin-bottom: 5px;
    align-self: flex-end;
  }
  .title {
    margin-bottom: 30px;
  }
</style>
