<template>
  <div class="vasion-white-background vld-parent document-container">
    <Loading
      :active.sync="isLoading"
      :is-full-page="loadingFullPage"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div v-if="errorLoadingDocument" class="error-div">
      <div>
        <label class="document-title">
          Unable to load the Document.
        </label>
      </div>
      <div>
        <label class="document-title">
          If you believe you should have access to this Document, please contact your System Administrator.
        </label>
      </div>
      <div class="error-back-div">
        <VasionButton
          id="error-loading-back"
          :classProp="'primary'"
          :isFullWidth="false"
          @vasionButtonClicked="backToPreviousView()"
        >
          Back
        </VasionButton>
        <VasionButton
          v-if="hasAdditionalDocumentIds"
          id="save-attributes-button"
          :classProp="'primary'"
          :isFullWidth="false"
          @vasionButtonClicked="nextDocument()"
        >
          Next
        </VasionButton>
      </div>
    </div>
    <div v-else class="innerDiv">
      <div v-if="documentID <= 0">
        <Loading
          :is-full-page="loadingFullPage"
          :color="loaderColor"
          loader="dots"
          :background-color="loaderBackgroundColor"
        />
      </div>
      <div v-else>
        <div class="headerDiv">
          <div>
            <VasionButton
              v-if="!isSignatureDocLinkView"
              id="btnBack"
              name="btnBack"
              title="Back"
              :icon="'VasionArrowLeftIcon'"
              @vasionButtonClicked="backToPreviousView()"
            />
            <div v-else style="width: 10px;" />
          </div>
          <div v-if="(isInSignatureMode || isInSignatureApp) && signatureDocumentGUID" id="signature-document-title-container">
            <div class="vasion-page-title-black" style="font-size: 20px; margin: 0px 0px 4px 0px;">
              {{ documentName }}
            </div>
            <div class="vasion-page-subheader-light" style="font-size: 12px">
              ID: {{ signatureDocumentGUID }}
            </div>
          </div>
          <div v-else class="document-title">
            <span v-if="VersionNumber">{{ VersionNumber }}-</span>{{ documentName }}
          </div>
          <div class="md-layout-item vasion-flex-end">
            <div v-if="hasPendingSignatureZones" class="md-layout-item vasion-flex-end headerDiv">
              <div class="required-fields">
                Completed: <div class="zones-counter">
                  {{ signedSignatureZones }} / {{ totalSignatureZones }}
                </div>
              </div>
              <div v-if="hasMoreThanOnePendingSignatureZone" class="alignCenter">
                <VasionButton
                  id="previous-zone-button"
                  name="previous-zone-button"
                  icon="VasionChevronLeft"
                  :classProp="'secondary-grey'"
                  @vasionButtonClicked="goToPreviousZone"
                />
                <VasionButton
                  id="next-zone-button"
                  :classProp="'secondary-grey'"
                  :isFullWidth="false"
                  @vasionButtonClicked="goToNextZone"
                >
                  <div class="align-text-and-icon">
                    Next
                    <VasionChevronRight />
                  </div>
                </VasionButton>
              </div>
              <div v-else>
                <VasionButton
                  id="go-to-zone-button"
                  :classProp="'secondary-grey'"
                  :isFullWidth="false"
                  @vasionButtonClicked="goToPage(pendingSignaturesPageNumbers[0])"
                >
                  Go to Zone
                </VasionButton>
              </div>
            </div>
            <VasionButton
              v-if="showSaveButton && !isSignatureDocLinkView"
              id="save-attributes-button"
              :classProp="'primary'"
              :isFullWidth="false"
              :isDisabled="!saveButtonActive"
              @vasionButtonClicked="saveAllTypes(false, true)"
            >
              Save
            </VasionButton>
            <VasionButton
              v-if="hasAdditionalDocumentIds"
              id="save-attributes-button"
              :classProp="'primary'"
              :isFullWidth="false"
              :isDisabled="!saveButtonActive"
              @vasionButtonClicked="saveAllTypes(false, true, true)"
            >
              Save and Next
            </VasionButton>
            <VasionButton
              v-if="hasAdditionalDocumentIds"
              id="save-attributes-button"
              :classProp="'primary'"
              :isFullWidth="false"
              @vasionButtonClicked="nextDocument()"
            >
              Next
            </VasionButton>
          </div>
        </div>

        <!-- START OF TOOLBAR -->
        <div class="toolbar-div" :class="{ 'toolbar-div-not-in-document-view': !isInDocumentView }">
          <!-- PAGINATION -->
          <VasionPager
            v-if="!isNativeDocument && isInDocumentView"
            :currentPageNumber="currentPageNumber"
            :startingPageNumber="parseInt($route.params.startingPage, 10)"
            :showPageSize="false"
            :totalPages="totalPages"
            @pageChanged="goToPage"
          />

          <!-- ZOOM IN, ZOOM OUT -->
          <div v-if="!isNativeDocument && isInDocumentView" id="toolbar-zoom" class="toolbar-height">
            <div class="toolbar-divider" />
            <VasionButton
              id="btnZoomIn"
              class="toolbar-icon"
              name="btnZoomIn"
              title="Zoom In"
              :icon="'VasionZoomInIcon'"
              :isDisabled="disableZoomIn"
              @vasionButtonClicked="zoomIn()"
            />
            <VasionButton
              id="btnZoomOut"
              class="toolbar-icon"
              name="btnZoomOut"
              title="Zoom Out"
              :icon="'VasionZoomOutIcon'"
              :isDisabled="disableZoomOut"
              @vasionButtonClicked="zoomOut()"
            />
          </div>

          <!-- UPLOAD NEW VERSION, PRINT, DOWNLOAD, EMAIL, GENERATE PDF -->
          <div
            v-if="canUploadNewVersionDocument || canPrintDocument || canDownloadDocument || canEmailDocument || canGeneratePDF"
            id="toolbar-print-upload-email"
            class="toolbar-height"
            :class="{
              'toolbar-height-not-native-document-view': !isInDocumentView || isNativeDocument,
              'toolbar-margin-not-document-view': !isInDocumentView
            }"
          >
            <div v-if="isInDocumentView" class="toolbar-divider" />
            <VasionButton
              v-if="canUploadNewVersionDocument && isInDocumentView && !isInSignatureMode"
              id="btnUploadVersion"
              class="toolbar-icon"
              name="btnUploadVersion"
              title="Upload New Version"
              :icon="'VasionFileCopyIcon'"
              @vasionButtonClicked="showUploadNewVersionModal = !showUploadNewVersionModal"
            />
            <VasionButton
              v-if="canPrintDocument && !isNativeDocument"
              id="btnPrint"
              class="toolbar-icon"
              name="btnPrint"
              title="Print"
              :icon="'VasionPrintIcon'"
              @vasionButtonClicked="handlePrintClick()"
            />
            <VasionButton
              v-if="canDownloadDocument"
              id="btnDownload"
              class="toolbar-icon"
              name="btnDownload"
              title="Download"
              :icon="'VasionDocumentDownloadIcon'"
              @vasionButtonClicked="downloadDocumentClick()"
            />
            <VasionButton
              v-if="canEmailDocument"
              id="email-button"
              class="toolbar-icon"
              name="email-button"
              title="Email"
              :icon="'VasionEmailIcon'"
              @vasionButtonClicked="emailButtonClick()"
            />
            <VasionButton
              v-if="canGeneratePDF && !isInSignatureMode && !isNativeDocument"
              id="pdf-button"
              class="toolbar-icon"
              name="pdf-button"
              title="Generate PDF"
              :icon="'VasionPictureAs'"
              @vasionButtonClicked="createPDFVersionClick"
            />
          </div>

          <div v-if="!showToolbar720" id="toolbar-add-delete-pages-and-rotate" class="flex-display">
            <!-- ADD PAGES, DELETE PAGES -->
            <div v-if="canAddOrDelete" id="toolbar-add-delete-pages" class="toolbar-height">
              <div class="toolbar-divider" />
              <VasionButton
                id="btnAddPagesId"
                class="toolbar-icon"
                name="btnAddPages"
                title="Add\Replace Pages"
                :icon="'VasionAddNote'"
                @vasionButtonClicked="() => showAddPagesModal = true"
              />
              <VasionButton
                v-if="canDeletePages"
                id="btnDeletePagesId"
                class="toolbar-icon"
                name="btnDeletePages"
                title="Delete Pages"
                :icon="'VasionRemoveNote'"
                @vasionButtonClicked="showDeletePagesDialog = true"
              />
            </div>

            <!-- ROTATE -->
            <div v-if="!isNativeDocument && isInDocumentView && !isInSignatureMode" id="toolbar-rotate-dropdown" class="toolbar-button">
              <div class="toolbar-divider" />
              <md-button
                class="md-icon-button md-dense double-icon toolbar-icon"
                title="Rotate"
                @click="toggleRotateActions($event)"
              >
                <VasionRotateLeftIcon /><VasionArrowDropDown16Icon class="drop-arrow-icon" />
              </md-button>
            </div>
          </div>

          <div v-if="!showEllipsisIcon" id="toolbar-annotations" class="flex-display">
            <!-- ANNOTATIONS: BLACK, WHITE, HIGHLIGHT, TEXT, STAMP, SIGNATURE, SAVE -->
            <div v-if="isInDocumentView && !isInSignatureMode" class="toolbar-height">
              <div class="toolbar-divider" />
              <!-- // TODO: Get black and while rectangle icons for the below buttons, then convert to VasionButtons -->
              <md-button
                v-if="canAddAnnotation"
                id="btnBlackRedaction"
                :disabled="isReadOnly"
                class="md-icon-button toolbar-button toolbar-icon"
                title="Black Redaction"
                @click="addNewAnnotation(annotation.redaction.black)"
              >
                <div class="Rectangle-2020" :class="{ 'vasion-icon-disabled': isReadOnly }" />
              </md-button>
              <md-button
                v-if="canAddAnnotation"
                id="btnWhiteRedaction"
                :disabled="isReadOnly"
                class="md-icon-button toolbar-button toolbar-icon"
                title="White Redaction"
                @click="addNewAnnotation(annotation.redaction.white)"
              >
                <div class="Rectangle-2020-White" :class="{ 'vasion-icon-disabled': isReadOnly }" />
              </md-button>
              <VasionButton
                v-if="canAddAnnotation && !isInSignatureMode"
                id="btnTextAnnotation"
                class="toolbar-icon"
                name="btnTextAnnotation"
                title="Highlight"
                :icon="'VasionHighlight'"
                :isDisabled="isReadOnly"
                @vasionButtonClicked="addNewAnnotation(annotation.highlight)"
              />
              <VasionButton
                v-if="canAddAnnotation && !isInSignatureMode"
                id="btnTextAnnotationCanAdd"
                class="toolbar-icon"
                name="btnTextAnnotationCanAdd"
                title="Text"
                :icon="'VasionTextAnnotationIcon'"
                :isDisabled="isReadOnly"
                @vasionButtonClicked="addNewAnnotation(annotation.text);"
              />
              <md-button
                v-if="canAddAnnotation"
                id="stampButton"
                class="md-icon-button md-dense double-icon toolbar-button toolbar-icon"
                title="Stamp"
                @click="toggleShowAddStampList($event);
                        addNewAnnotation(annotation.stamp);"
              >
                <VasionStampIcon /><VasionArrowDropDown16Icon class="drop-arrow-icon" />
              </md-button>
              <md-button
                v-if="canPerformDigitalSignature"
                id="btnSignature"
                class="md-icon-button md-dense double-icon toolbar-button toolbar-icon"
                title="Signature"
                @click="clickSignatureToolbarButton($event)"
              >
                <VasionGestureIcon /><VasionArrowDropDown16Icon class="drop-arrow-icon" />
              </md-button>
              <VasionButton
                v-if="canAddAnnotation && canBurnAnnotations"
                id="btnBurnAnnotations"
                class="toolbar-icon"
                name="btnBurnAnnotations"
                title="Burn Annotations"
                :icon="'VasionFlame'"
                @vasionButtonClicked="toggleBurnAnnotations()"
              />
            </div>

            <!-- SIGNATURE DOCUMENT HISTORY -->
            <div v-if="isInSignatureMode" id="toolbar-history" class="toolbar-height">
              <div class="toolbar-divider" />
              <VasionButton
                id="documentHistory"
                class="toolbar-icon"
                name="documentHistory"
                title="Document History"
                :icon="'VasionDocumentHistory'"
                @vasionButtonClicked="showHistory()"
              />
            </div>

            <div v-if="isInSignatureMode && hasCertificate" id="toolbar-certificate" class="toolbar-height">
              <VasionButton
                id="documentCertificate"
                class="toolbar-icon"
                name="documentCertificate"
                title="Document Certificate"
                :icon="'VasionCertificateIcon'"
                @vasionButtonClicked="showCertificate()"
              />
            </div>
          </div>

          <!-- ELLIPSIS ICON -->
          <div v-if="isInDocumentView && showEllipsisIcon" id="toolbar-ellipsis" class="toolbar-height">
            <div class="toolbar-divider" />
            <VasionButton
              id="toolbar-ellipsis-btn"
              class="toolbar-icon"
              name="toolbar-ellipsis"
              title="Show More"
              :icon="'VasionEllipsisIcon'"
              @vasionButtonClicked="showEllipsisToolbar = !showEllipsisToolbar"
            />
          </div>

          <!-- TOGGLE VIEW, SHOW RIGHT PANEL (SMALL SCREENS) -->
          <div id="toggle-btn">
            <div class="md-layout-item vasion-flex-end">
              <VasionButton
                v-if="!isInSignatureMode && !isSignatureDocLinkView"
                id="toggle-document-view-button"
                name="toggle-document-view-button"
                title="Change View"
                :icon="viewModeIcon"
                @vasionButtonClicked="toggleViewClick"
              />
              <VasionEllipsisMenu
                v-if="showViewModeMenu"
                :buttonConfig="viewMenuButtonConfig"
                :style="'top:180px;'"
                @ellipsis-button-clicked="viewMenuSelectionMade"
                @close="closeViewModeMenu"
              />
            </div>
            <VasionButton
              id="show-sidebar-button"
              :class="{ 'side-panel-expanded' : !rightSidePanelMobile }"
              name="show-sidebar-button"
              title="Show Sidebar"
              :iconFill="rightSidePanelMobile ? 'black' : '#ff4702'"
              :icon="'VasionSideBarIcon'"
              @vasionButtonClicked="rightSidePanelMobile = !rightSidePanelMobile"
            />
          </div>
        </div>
        <!-- END OF TOOLBAR -->

        <!-- START OF: ELLIPSIS TOOLBAR -->
        <div class="center-flex-content">
          <div v-if="isInDocumentView && showEllipsisToolbar" id="ellipsis-toolbar">
            <div v-if="isInDocumentView && showToolbar720" class="flex-display">
              <!-- ADD PAGES, DELETE PAGES -->
              <div v-if="canAddOrDelete" id="toolbar-add-delete-pages" class="toolbar-height">
                <VasionButton
                  id="btnAddPagesId"
                  class="toolbar-icon"
                  name="btnAddPages"
                  title="Add\Replace Pages"
                  :icon="'VasionAddNote'"
                  @vasionButtonClicked="() => showAddPagesModal = true"
                />
                <VasionButton
                  v-if="canDeletePages"
                  id="btnDeletePagesId"
                  class="toolbar-icon"
                  name="btnDeletePages"
                  title="Delete Pages"
                  :icon="'VasionRemoveNote'"
                  @vasionButtonClicked="showDeletePagesDialog = true"
                />
              </div>

              <!-- ROTATE -->
              <div v-if="!isNativeDocument && isInDocumentView && !isInSignatureMode" id="toolbar-rotate-dropdown" class="toolbar-button">
                <div class="toolbar-divider" />
                <md-button
                  class="md-icon-button md-dense double-icon toolbar-icon"
                  title="Rotate"
                  @click="toggleRotateActions($event)"
                >
                  <VasionRotateLeftIcon /><VasionArrowDropDown16Icon class="drop-arrow-icon" />
                </md-button>
              </div>
            </div>

            <!-- ANNOTATIONS: BLACK, WHITE, HIGHLIGHT, TEXT, STAMP, SIGNATURE, SAVE -->
            <div v-if="isInDocumentView && !isInSignatureMode" class="toolbar-height">
              <div v-if="showToolbar720" class="toolbar-divider" />
              <!-- // TODO: Get black and while rectangle icons for the below buttons, then convert to VasionButtons -->
              <md-button
                v-if="canAddAnnotation"
                id="btnBlackRedaction"
                :disabled="isReadOnly"
                class="md-icon-button toolbar-button toolbar-icon"
                title="Black Redaction"
                @click="addNewAnnotation(annotation.redaction.black)"
              >
                <div class="Rectangle-2020" :class="{ 'vasion-icon-disabled': isReadOnly }" />
              </md-button>
              <md-button
                v-if="canAddAnnotation"
                id="btnWhiteRedaction"
                :disabled="isReadOnly"
                class="md-icon-button toolbar-button toolbar-icon"
                title="White Redaction"
                @click="addNewAnnotation(annotation.redaction.white)"
              >
                <div class="Rectangle-2020-White" :class="{ 'vasion-icon-disabled': isReadOnly }" />
              </md-button>
              <VasionButton
                v-if="canAddAnnotation && !isInSignatureMode"
                id="btnTextAnnotation"
                class="toolbar-icon"
                name="btnTextAnnotation"
                title="Highlight"
                :icon="'VasionHighlight'"
                :isDisabled="isReadOnly"
                @vasionButtonClicked="addNewAnnotation(annotation.highlight)"
              />
              <VasionButton
                v-if="canAddAnnotation && !isInSignatureMode"
                id="btnTextAnnotationCanAdd"
                class="toolbar-icon"
                name="btnTextAnnotationCanAdd"
                title="Text"
                :icon="'VasionTextAnnotationIcon'"
                :isDisabled="isReadOnly"
                @vasionButtonClicked="addNewAnnotation(annotation.text);"
              />
              <md-button
                v-if="canAddAnnotation"
                id="stampButton"
                class="md-icon-button md-dense double-icon toolbar-button toolbar-icon"
                title="Stamp"
                @click="toggleShowAddStampList($event);
                        addNewAnnotation(annotation.stamp);"
              >
                <VasionStampIcon /><VasionArrowDropDown16Icon class="drop-arrow-icon" />
              </md-button>
              <md-button
                v-if="canPerformDigitalSignature"
                id="btnSignature"
                class="md-icon-button md-dense double-icon toolbar-button toolbar-icon"
                title="Signature"
                @click="clickSignatureToolbarButton($event)"
              >
                <VasionGestureIcon /><VasionArrowDropDown16Icon class="drop-arrow-icon" />
              </md-button>
              <VasionButton
                v-if="canAddAnnotation && canBurnAnnotations"
                id="btnBurnAnnotations"
                class="toolbar-icon"
                name="btnBurnAnnotations"
                title="Burn Annotations"
                :icon="'VasionFlame'"
                @vasionButtonClicked="toggleBurnAnnotations()"
              />
            </div>

            <!-- SIGNATURE DOCUMENT HISTORY -->
            <div v-if="isInSignatureMode" id="toolbar-history" class="toolbar-height">
              <div class="toolbar-divider" />
              <VasionButton
                id="documentHistory"
                class="toolbar-icon"
                name="documentHistory"
                title="Document History"
                :icon="'VasionDocumentHistory'"
                @vasionButtonClicked="showHistory()"
              />
            </div>
 
            <div v-if="isInSignatureMode && hasCertificate" id="toolbar-certificate" class="toolbar-height">
              <VasionButton
                id="documentCertificate"
                class="toolbar-icon"
                name="documentCertificate"
                title="Document Certificate"
                :icon="'VasionCertificateIcon'"
                @vasionButtonClicked="showCertificate()"
              />
            </div>
          </div>
        </div>
        <!-- END OF: ELLIPSIS TOOLBAR -->

        <div class="md-layout remainingDocumentSection">
          <div v-if="!isNativeDocument && !isInSignatureMode && !isSignatureDocLinkView" class="action-links vasion-white-background">
            <VasionButton
              v-if="canViewDocumentHistory && !isInSignatureMode"
              id="document-history"
              class="action-link"
              name="document-history"
              title="History"
              :icon="'VasionHistoryIcon'"
              :isDense="true"
              @vasionButtonClicked="showHistory()"
            />

            <VasionButton
              v-if="canViewDocumentNotes && !isInSignatureMode"
              id="document-notes"
              class="action-link"
              name="document-notes"
              title="Notes"
              :icon="'VasionNotesIcon'"
              :isDense="true"
              @vasionButtonClicked="showNotesDialog = !showNotesDialog"
            />

            <VasionButton
              v-if="canViewDocumentVersions && versionCount > 0 && !isInSignatureMode"
              id="document-versions"
              class="action-link"
              name="document-versions"
              title="Versions"
              :icon="'VasionVersionsIcon'"
              :isDense="true"
              @vasionButtonClicked="toggleVersionsView()"
            />

            <VasionButton
              v-if="canViewAttachedDocuments && !isInSignatureMode"
              id="document-attachments"
              class="action-link"
              name="document-attachments"
              title="Attachments"
              :icon="'VasionAttachmentsIcon'"
              :isDense="true"
              @vasionButtonClicked="showAttachedDocumentsDialog = true"
            />

            <VasionButton
              v-if="tasks && tasks.length && tasks.length > 0 && !isInSignatureMode"
              id="document-tasks"
              class="action-link"
              name="document-tasks"
              title="Tasks"
              :icon="'VasionTasksCheckIcon'"
              :isDense="true"
              @vasionButtonClicked="showTasksDialog = !showTasksDialog"
            />

            <VasionButton
              v-if="workflowComments && workflowComments.length && workflowComments.length > 0 && !isInSignatureMode"
              id="document-comments"
              class="action-link"
              name="document-comments"
              title="Workflow History"
              :icon="'VasionWorkflowIcon'"
              :isDense="true"
              @vasionButtonClicked="historyMode = 'comments'; showHistoryDialog = !showHistoryDialog"
            />

            <VasionButton
              id="document-line-tems"
              class="action-link"
              name="document-line-tems"
              title="Line Items"
              :icon="'VasionGridIcon'"
              :isDense="true"
              @vasionButtonClicked="showLineItemsDialog = !showLineItemsDialog"
            />
          </div>
          <div v-if="!isNativeDocument && !isInSignatureMode" class="divider" />
          <!--
          <div v-if="isNativeDocument === false" class="mainThumbnails vasion-white-background">
            <div class='vasion-white-background thumbnailDiv' :title="item.pageNumber" v-for="item in thumbnails" v-bind:key="item.pageNumber" @click="goToPage(item.pageNumber)">
              <DocumentImage class='thumbnailImage' :key="refreshThumbnailKey" :class="{ thumbnailImageSelected: item.pageNumber === currentPageNumber }"
                :isThumbnail="true" :documentID="documentID" :pageNumber="item.pageNumber" :width="124" :height="162" :fileDetail="document.FileDetail" :loadOnVisible="true" ></DocumentImage>
            </div>
          </div>
          -->
          <div v-if="!isNativeDocument && !isInSignatureMode" class="divider" />
          <div id="image-div" class="md-layout-item">
            <div v-show="isInDocumentView" :id="idWithPageNumber" class="lightestGreyBackground mainImageDiv">
              <DocumentImage
                v-if="!isNativeDocument"
                ref="mainImage"
                :documentID="documentID"
                :pageNumber="currentPageNumber"
                :width="viewerWidth"
                :height="viewerHeight"
                :fileDetail="document.FileDetail"
                :largeDefaultLoadSize="true"
                :canDeleteAnnotations="!isInSignatureMode"
                @imageLoaded="mainImageLoaded"
                @backgroundImageLoaded="setZoom()"
              />
              <div v-else-if="canDownloadDocument">
                <div>
                  Click here to download this file.
                </div>
                <VasionButton
                  id="download-native-button"
                  :classProp="'primary'"
                  @vasionButtonClicked="downloadDocumentClick()"
                >
                  Download
                </vasionbutton>
              </div>
            </div>
            <div v-show="isInDataView" class="lightestGreyBackground data-view-container">
              <div class="data-view">
                <h1 class="data-view-header">
                  {{ formName }}
                </h1>
                <TheDocumentData
                  ref="documentData"
                  :document="document"
                  :currentAttributeFormId="currentAttributeFormID"
                  @isValid="markIsValid"
                  @notValid="markNotValid"
                  @clean="markClean"
                  @dirty="markDirty('fromTheDocumentData')"
                />
              </div>
            </div>
            <div v-show="supportPDFViewer && isInPDFView" class="pdfiFrame">
              <Loading
                :active.sync="isPdfLoading"
                :is-full-page="false"
                :color="loaderColor"
                loader="dots"
                :background-color="loaderBackgroundColor"
              />
              <iframe
                id="pdfIFrame"
                marginheight="0"
                frameborder="0"
                :src="streamPDFData"
              />
            </div>
          </div>
          <div class="divider" />
          <div
            v-if="(buttonOptions.length || isInDocumentView)"
            id="attributesTabDiv"
            :class="{ 'attributes-tab-div-closed': rightSidePanelMobile, 'attributes-tab-div': !rightSidePanelMobile }"
            class="md-layout-item"
          >
            <div class="right-side-panel-content">
              <div v-if="showWorkflowDiv" class="attributes-padding-div">
                <div v-for="(button, index) in buttonOptions" :key="index" class="attributes-padding-div-buttons">
                  <VasionButton
                    :id="button.UniqueName"
                    :classProp="button.Label === 'Approve' ? 'primary' : 'secondary'"
                    :isFullWidth="true"
                    @vasionButtonClicked="setWorkflowAction(button.UniqueName)"
                  >
                    {{ button.Label }}
                  </VasionButton>
                </div>
                <md-field id="new-comment">
                  <label v-if="!newComment">Comment ...</label>
                  <md-textarea v-model="newComment" />
                </md-field>
                <div class="alignRight">
                  <VasionButton
                    :id="'comment'"
                    :classProp="'secondary-grey'"
                    :isDisabled="!newComment"
                    @vasionButtonClicked="commentButtonClicked"
                  >
                    Comment
                  </VasionButton>
                </div>
              </div>
              <div v-if="isInSignatureMode" class="attributes-padding-div">
                <VasionButton
                  v-if="parseInt(UserID, 10) === parseInt(signatureDocumentInitiatorUserID, 10)"
                  id="recall-button"
                  classProp="primary"
                  :isFullWidth="true"
                  @vasionButtonClicked="showRecallPrompt = true"
                >
                  Recall
                </VasionButton>
                <VasionButton
                  :id="'complete'"
                  :classProp="'primary'"
                  :isFullWidth="true"
                  :isDisabled="!signatureZonesCompleted"
                  @vasionButtonClicked="showCompleteSignatureModal = !showCompleteSignatureModal"
                >
                  Complete
                </VasionButton>
                <VasionButton
                  v-if="isReviewer"
                  :id="'reviewed'"
                  :classProp="'primary'"
                  :isFullWidth="true"
                  @vasionButtonClicked="reviewedSignatureDocument"
                >
                  Reviewed
                </VasionButton>
                <VasionButton
                  :id="'reject'"
                  :classProp="'secondary'"
                  :isFullWidth="true"
                  :isDisabled="!hasPendingSignatureZones && !isReviewer"
                  @vasionButtonClicked="toggleRejectSignature"
                >
                  Reject
                </VasionButton>
                <textarea
                  v-model="signatureComment"
                  :class="{ 'error-state-container': missingCommentForReject }"
                  class="comment-textarea vasion-textarea"
                  :placeholder="missingCommentForReject ? 'a comment is required to reject...' : 'Leave a comment...'"
                  rows="2"
                />
                <div class="alignRight">
                  <VasionButton
                    :id="'comment'"
                    :classProp="'secondary-grey'"
                    :isDisabled="!signatureComment"
                    @vasionButtonClicked="signatureCommentButtonClicked"
                  >
                    Comment
                  </VasionButton>
                </div>
              </div>
              <div>
                <div
                  v-if="isInDocumentView"
                  id="attribute-top-section"
                  :class="{ 'attribute-top-section-with-workflow': showWorkflowDiv, 'attribute-top-section-without-workflow': !showWorkflowDiv }"
                >
                  <VasionButton
                    v-if="showStartWorkflowButton"
                    id="btnStartWorkflow"
                    name="btnStartWorkflow"
                    title="Start Workflow"
                    :classProp="'secondary'"
                    :isFullWidth="true"
                    @vasionButtonClicked="toggleStartWorkflow()"
                  >
                    Start Workflow
                  </VasionButton>
                  <div v-if="attributeForms && attributeForms.length !== 0">
                    <VasionDropList
                      v-if="!isSignatureDocLinkView"
                      v-slot="slotItem"
                      v-model="currentAttributeFormID"
                      :dataArray="attributeForms"
                      :isDisabled="isInSignatureMode"
                      :title="`${$formsLabel.toUpperCase()}`"
                      inputType="plain-list"
                      valueName="value"
                      displayName="name"
                      width="100%"
                      class="attribute-droplist"
                    >
                      {{ slotItem.item.name }}
                    </VasionDropList>
                    <VasionButton
                      v-show="showLookupButton"
                      id="database-lookup-button"
                      :classProp="'secondary'"
                      :isFullWidth="true"
                      @vasionButtonClicked="attributeFieldLookUp"
                    >
                      Database Lookup
                    </VasionButton>
                  </div>
                  <div v-else>
                    <div class="no-objects-warning">
                      <b>Warning:</b> No {{ $formsLabelPlural.toLowerCase() }} were found.
                    </div>
                    <VasionInput
                      class="attribute-droplist"
                      inputType="top-white"
                      isDisabled
                      :placeholder="`No ${$formsLabelPlural.toLowerCase()} found.`"
                      :title="$formsLabel.toUpperCase()"
                      width="100%"
                    />
                  </div>
                </div>
                <div v-if="isInDocumentView" class="index-field-list-padding">
                  <IndexFieldList
                    ref="attributeFieldsComponent"
                    :fields="indexFields"
                    :enforceFieldSecurity="true"
                    :selectorOnly="false"
                    :showSubmit="false"
                    :fieldWidth="225"
                    :isInDocumentView="isInDocumentView"
                    :useMultiSelectLists="true"
                    @fieldValueUpdated="updateLocalFieldValues"
                    @clean="markClean"
                    @dirty="markDirty"
                    @notValid="markNotValid"
                    @isValid="markIsValid"
                  />
                </div>
              </div>
              <div v-if="documentAttributeFields.length" class="padding-all">
                <VasionInput
                  v-if="document.Workflow"
                  id="workflow-name-text-box"
                  title="WORKFLOW NAME"
                  name="workflow-name-text-box"
                  placeholder=""
                  inputType="top-white"
                  class="bottom-margin"
                  :isDisabled="true"
                  :value="document.Workflow.Name"
                />
                <VasionInput
                  v-if="document.Workflow"
                  id="workflow-step-text-box"
                  title="WORKFLOW STEP"
                  name="workflow-step-text-box"
                  placeholder=""
                  inputType="top-white"
                  class="bottom-margin"
                  :isDisabled="true"
                  :value="document.Workflow.StepName"
                />
                <VasionInput
                  id="workflow-status-text-box"
                  title="WORKFLOW STATUS"
                  name="workflow-status-text-box"
                  placeholder=""
                  inputType="top-white"
                  class="bottom-margin"
                  :isDisabled="true"
                  :value="workflowStatus"
                />
                <VasionInput
                  id="workflow-approver-text-box"
                  title="WORKFLOW APPROVER"
                  name="workflow-approver-text-box"
                  placeholder=""
                  inputType="top-white"
                  class="bottom-margin"
                  :isDisabled="true"
                  :value="workflowApprover"
                />
                <VasionInput
                  id="workflow-initiator-text-box"
                  title="WORKFLOW INITIATOR"
                  name="workflow-initiator-text-box"
                  placeholder=""
                  inputType="top-white"
                  class="bottom-margin"
                  :isDisabled="true"
                  :value="workflowInitiator"
                />
              </div>
              <div class="padding-all">
                <div v-if="isInSignatureMode && !isSignatureDocLinkView" class="extra-padding-bottom">
                  <VasionInput
                    v-model="dueDate"
                    class="input-style"
                    inputType="top-white"
                    width="100%"
                    :isDisabled="true"
                    :title="dueDateLabel"
                  />
                </div>
                <VasionDatePicker
                  v-else-if="!isSignatureDocLinkView"
                  id="document-due-date"
                  :title="dueDateLabel"
                  :value="dueDate"
                  @input="setDueDate($event)"
                  @closed="dirtyDueDateState = true"
                />
                <VasionDropList
                  v-if="!isSignatureDocLinkView"
                  v-slot="slotItem"
                  v-model="priority"
                  :isDisabled="isInSignatureMode"
                  :dataArray="priorities"
                  :width="'100%'"
                  title="PRIORITY"
                  type="plain-list"
                  displayName="priorityName"
                  valueName="priorityID"
                  @input="dirtyPriorityState = true"
                >
                  {{ slotItem.item.priorityName }}
                </VasionDropList>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <VasionSnackbar
      id="vasion-snackbar"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />

    <TheDocumentVersions
      :active.sync="showVersions"
      :documentId="documentID"
      :isReadOnly="isReadOnly"
      @close="showVersions = !showVersions"
      @versionSelect="versionSelect"
    />

    <TheDocumentNotes
      :active.sync="showNotesDialog"
      :documentId="documentID"
      @close="showNotesDialog = !showNotesDialog"
      @goToPage="goToPageEmit"
    />

    <TheDocumentAttachments
      :active.sync="showAttachedDocumentsDialog"
      :documentId="documentID"
      :folderId="documentFolderID"
      @close="showAttachedDocumentsDialog = false"
    />

    <TheDocumentTasks
      :active.sync="showTasksDialog"
      :documentId="documentID"
      :tasks="tasks"
      :workflow="workflow"
      @close="showTasksDialog = !showTasksDialog"
      @tasks-updated="updateTasks"
    />

    <TheDocumentLineItems
      :active.sync="showLineItemsDialog"
      :documentId="documentID"
      @close="showLineItemsDialog = !showLineItemsDialog"
    />

    <md-dialog id="burn-annotations-dialog" :md-active.sync="showBurnAnnotations">
      <VasionConfirmationDialog
        :message="'Are you sure you want to Burn Annotations?'"
        @noButtonClick="toggleBurnAnnotations()"
        @yesButtonClick="confirmBurnAnnotations()"
      />
    </md-dialog>

    <md-dialog id="apply-signature-dialog" :md-active.sync="showAddSignatureDialog">
      <VasionApplySignature
        :hasSavedSignature="hasSavedSignature"
        @ok-click="addNewSignature"
        @cancel-click="toggleShowAddSignatureDialog"
      />
    </md-dialog>

    <md-dialog id="print-document-modal" :md-active.sync="showPrintModal">
      <VasionPrintOptions
        :supportIncludeHistory="signatureDocumentGUID != '' && !isInSignatureMode"
        @okClick="printSelectedDocuments"
        @cancelClick="showPrintModal = !showPrintModal"
      />
    </md-dialog>

    <md-dialog id="download-document-modal" :md-active.sync="showDownloadDocumentDialog">
      <VasionDownloadOptions
        :fileExtension="fileExtension"
        :supportIncludeHistory="signatureDocumentGUID != '' && !isInSignatureMode"
        @okClick="downloadDocument"
        @cancelClick="showDownloadDocumentDialog = !showDownloadDocumentDialog"
      />
    </md-dialog>

    <md-dialog id="email-modal" :md-active.sync="showEmailModal" class="email-modal-div">
      <DocumentEmail
        :documentIDString="documentIDString"
        :isInDataView="!isInDocumentView"
        :supportBurnAnnotations="isTiffOrPDF"
        :supportIncludeHistory="signatureDocumentGUID != '' && !isInSignatureMode"
        @email-result="showEmailSnackbar"
        @close="closeDocumentEmail"
      />
    </md-dialog>

    <md-dialog id="reassign-forward-modal" :md-active.sync="showGroupModal" class="email-modal-div">
      <VasionUserGroupSelect
        :modalTitle="modalTitle"
        :groupFilterId="userGroupAssignConfig.groupId"
        :userOnly="userGroupAssignConfig.userOnly"
        @noButtonClick="showGroupModal = !showGroupModal"
        @yesButtonClick="handleForwardReassign"
      />
    </md-dialog>

    <md-dialog id="multipleLookupDialog" :md-active.sync="showMultipleLookupDialog">
      <div>
        <MultipleLookups
          :tableValues="localFieldValues"
          @yesButtonClick="updateLookupValues"
          @noButtonClick="showMultipleLookupDialog = !showMultipleLookupDialog"
        />
      </div>
    </md-dialog>

    <VasionRotateDropDown
      v-if="showRotateActions && !isInSignatureMode"
      :style="dropdownLocation"
      :isReadOnly="isReadOnly"
      @loseFocus="showRotateActions = !showRotateActions"
      @rotateLeft="rotateLeft"
      @rotateRight="rotateRight"
      @permanentlyRotateLeft="permanentlyRotateLeft"
      @permanentlyRotateRight="permanentlyRotateRight"
    />

    <VasionGeneralModal
      id="upload-new-version-modal"
      :rejectButtonText="'CLOSE'"
      :showConfirmButton="false"
      modalTitle=""
      :modalType="'slot'"
      :sync="showUploadNewVersionModal"
      @noButtonClick="showUploadNewVersionModal = !showUploadNewVersionModal"
    >
      <div class="file-pond-block-wrapper non-dialog">
        <file-pond
          ref="pond"
          class="new-document"
          :server="serverOptions"
          :label-idle="filePondLabel"
        />
      </div>
    </VasionGeneralModal>

    <VasionGeneralModal
      :rejectButtonText="'CLOSE'"
      :showConfirmButton="false"
      modalTitle=""
      :modalType="'slot'"
      :sync="showUploadNewPageModal"
      @noButtonClick="toggleUploadAndPage"
    >
      <div class="file-pond-block-wrapper">
        <file-pond ref="pondPage" :server="serverOptionsPages" />
      </div>
    </VasionGeneralModal>

    <div v-if="addInitialsDropListActive" class="initials-drop-list" :style="dropdownLocation">
      <ul>
        <li v-if="userSignature" @click="addSavedSignature">
          <div class="signature-group">
            <img class="signature-image" :src="userSignature" alt="User Signature">
            <VasionRemoveCircleIcon class="circle-icon-color" />
          </div>
        </li>
        <li v-if="userInitials" @click="addSavedSignatureInitials">
          <div class="signature-group">
            <img class="signature-image" :src="userInitials" alt="User Initials">
            <VasionRemoveCircleIcon class="circle-icon-color" />
          </div>
        </li>
        <li class="add-new-signature" @click="toggleShowAddSignatureDialog">
          <div class="flex-display">
            <VasionGestureIcon class="gesture-gray" :class="{ 'vasion-icon-disabled': isReadOnly }" />
            <div>Add New Signature</div>
          </div>
        </li>
      </ul>
    </div>

    <div v-if="addStampsDropListActive" class="stamps-drop-list" :style="dropdownLocation">
      <ul>
        <li v-for="(stamp, index) in annotationImageList" :key="index" @click="addNewStamp(stamp)">
          <div class="stamp-group">
            <span>{{ stamp.name }}</span><br>
            <img class="stamp-image" :src="`${base64DataPrefix}${stamp.base64String}`" alt="Stamp">
          </div>
        </li>
      </ul>
    </div>

    <md-dialog
      id="customActionsDialog"
      :class="{'dropDownOpen': dropDownOpen}"
      :md-active.sync="showIndexPrompt"
    >
      <div class="document-title index-field-title">
        Field Information needed
      </div>
      <div class="custom-actions">
        <IndexFieldList
          ref="attributeFieldsComponent"
          :fields="actionsFieldList"
          :enforceFieldSecurity="true"
          :sideView="true"
          :selectorOnly="false"
          :showSubmit="true"
          :fieldWidth="225"
          :isInDocumentView="isInDocumentView"
          :useMultiSelectLists="true"
          :forPrompt="true"
          @fieldValueUpdated="updateLocalFieldValues"
          @submitted="handleIndexPrompt"
          @submitCancelled="showIndexPrompt = !showIndexPrompt"
          @openDropDown="changeModalClass"
        />
      </div>
    </md-dialog>

    <VasionGeneralModal
      :rejectButtonText="'CANCEL'"
      :confirmButtonText="'I Agree'"
      modalTitle="Almost Done"
      :modalType="'slot'"
      :sync="showCompleteSignatureModal"
      @confirmButtonClick="completeSignatureDocument"
      @noButtonClick="showCompleteSignatureModal = false"
    >
      <div id="confirmCompleteSignature">
        {{ confirmCompleteSignatureMessage }}
      </div>
    </VasionGeneralModal>

    <VasionGeneralModal
      :rejectButtonText="'No'"
      :confirmButtonText="'Yes'"
      :modalType="'message'"
      :buttonGroup="'center'"
      message="Are you sure you want to Reject?"
      :sync="showRejectSignatureModal"
      @confirmButtonClick="rejectSignatureDocument"
      @noButtonClick="showRejectSignatureModal = !showRejectSignatureModal"
    />

    <VasionGeneralModal
      :rejectButtonText="'No'"
      :confirmButtonText="'Yes'"
      :modalType="'message'"
      :buttonGroup="'center'"
      message="Are you sure you want to Recall?"
      :sync="showRecallPrompt"
      @confirmButtonClick="recallSignatureDocument"
      @noButtonClick="showRecallPrompt = false"
    />

    <VasionGeneralModal
      ref="addPageModal"
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Upload'"
      modalTitle="Add Pages"
      :modalType="'slot'"
      :confirmButtonDisabled="!arePagesReadyForUpload"
      :sync="showAddPagesModal"
      @noButtonClick="clearPageSelectionAndInsertOption"
      @confirmButtonClick="uploadDocument"
    >
      <AddPages
        ref="addPage"
        :currentPageNumber="currentPageNumber"
        :totalPages="totalPages"
        @add="uploadNewPageToggle"
        @upload="uploadPage"
        @failure="showUploadFailure"
        @success="reloadDocument($route.params.documentID); clearPageSelectionAndInsertOption()"
      />
    </VasionGeneralModal>

    <VasionGeneralModal
      id="prompt-password"
      :rejectButtonText="'CANCEL'"
      :confirmButtonText="'OK'"
      :modalType="'slot'"
      :message="'Are you sure'"
      :sync="showPromptPassword"
      @noButtonClick="enteredPassword = ''; showPromptPassword = !showPromptPassword"
      @confirmButtonClick="comparePasswords"
    >
      <div class="prompt-password-dialog">
        <div class="password-label">
          Please type the correct password
        </div>
        <div class="entered-password">
          <VasionInput
            v-model="enteredPassword"
            class="input-style"
            inputType="top-white"
            placeholder="Enter Password..."
            type="password"
            autocomplete="off"
          />
        </div>
      </div>
    </VasionGeneralModal>

    <md-dialog
      id="start-workflow-select-dialog"
      class="dialog-window"
      :md-click-outside-to-close="false"
      :md-active.sync="showWorkflowList"
    >
      <VasionSelectWorkflow
        :workflowList="workflowList"
        :showErrorLabel="showErrorLabel"
        @cancelButtonClick="showWorkflowList = !showWorkflowList"
        @continueButtonClick="continueWorkflowSelected"
      />
    </md-dialog>
    <md-dialog id="reassign-forward-modal" :md-active.sync="showUserSelectModal" class="email-modal-div">
      <VasionUserGroupSelect
        :modalTitle="'Select User'"
        :groupFilterId="userGroupAssignConfig.groupId"
        :userOnly="true"
        @noButtonClick="showUserSelectModal = !showUserSelectModal"
        @yesButtonClick="handleUserSelected"
      />
    </md-dialog>
    <md-dialog id="action-password-prompt" :md-active.sync="showActionPasswordDialog">
      <PasswordPrompt
        ref="passwordPrompt"
        inputTitle="Enter Workflow Password"
        @close="showActionPasswordDialog = false"
        @passwordEntered="setActionPassword"
      />
    </md-dialog>
    <VasionGeneralModal
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Delete'"
      :confirmButtonDisabled="disableDeletePagesButton"
      modalTitle="Delete Pages"
      :modalType="'slot'"
      :sync="showDeletePagesDialog"
      @confirmButtonClick="showDeletePagesConfirmDialog = true"
      @noButtonClick="showDeletePagesDialog = !showDeletePagesDialog"
    >
      <DocumentDeletePages
        :documentID="documentID"
        :pageNumber="currentPageNumber"
        :fileDetail="document.FileDetail"
        @updateSelectedPages="updateSelectedPages"
      />
    </VasionGeneralModal>
    <VasionGeneralModal
      :rejectButtonText="'No'"
      :confirmButtonText="'Yes'"
      :modalType="'message'"
      :buttonGroup="'center'"
      message="Are you sure you want to Delete these pages?"
      :sync="showDeletePagesConfirmDialog"
      @confirmButtonClick="deletePages"
      @noButtonClick="showDeletePagesConfirmDialog = !showDeletePagesConfirmDialog"
    />
    <VasionGeneralModal
      :modalType="'slot'"
      :hideButtons="true"
      :sync="showHistoryDialog"
      :modalTitle="historyModalTitle"
      @noButtonClick="showHistoryDialog = !showHistoryDialog"
    >
      <div v-if="signatureDocumentGUID && !isInSignatureMode" id="history-tab-buttons" class="md-layout">
        <VasionButton :classProp="documentTabClass" @vasionButtonClicked="signatureTabSelected = false">
          Document
        </VasionButton>
        <VasionButton :classProp="signatureTabClass" @vasionButtonClicked="signatureTabSelected = true">
          Signature
        </VasionButton>
      </div>
      <DocumentSignatureHistory
        v-if="signatureDocumentGUID && signatureTabSelected"
        :documentHistory="signatureDocumentHistoryValues"
        @close="showHistoryDialog = !showHistoryDialog"
        @exportToPdf="exportSigDocumentToPDF()"
      />
      <TheDocumentHistory
        v-if="!signatureTabSelected"
        :comments="workflowComments"
        :documentId="documentID"
        :mode="historyMode"
        @close="showHistoryDialog = !showHistoryDialog"
      />
    </VasionGeneralModal>
    <VasionGeneralModal
      id="cancel-confirmation-modal"
      :confirmButtonText="'YES'"
      :rejectButtonText="'NO'"
      :modalType="'message'"
      :buttonGroup="'center'"
      :message="'Your changes have not been saved, are you sure you want to navigate away?'"
      :sync="showDirtyModal"
      @confirmButtonClick="dirtyModalOKClick()"
      @noButtonClick="dirtyModalCancelClick()"
    />
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
// Out of order due to the fact that loading needs to happen before Apply Signature
import Loading from 'vue-loading-overlay';
import DocumentEmail from '@/components/document/DocumentEmail.vue';
import DocumentDeletePages from '@/components/document/DocumentDeletePages.vue';
import DocumentSignatureHistory from '@/components/document/DocumentSignatureHistory.vue';
import TheDocumentData from '@/views/Document/TheDocumentData.vue';
import AddPages from '@/components/storage/AddPages.vue';
import DocumentImage from '@/components/document/DocumentImage.vue';
import IndexFieldList from '@/components/IndexFieldList.vue';
import MultipleLookups from '@/components/vault/MultipleLookups.vue'
import TheDocumentAttachments from '@/components/document/TheDocumentAttachments.vue';
import TheDocumentHistory from '@/components/document/TheDocumentHistory.vue';
import TheDocumentLineItems from '@/components/document/TheDocumentLineItems.vue';
import TheDocumentNotes from '@/components/document/TheDocumentNotes.vue';
import TheDocumentTasks from '@/components/document/TheDocumentTasks.vue';
import TheDocumentVersions from '@/components/document/TheDocumentVersions.vue';
import { toBase64 } from '@/store/helperModules/storage.module'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import PasswordPrompt from '@/components/security/PasswordPrompt.vue'
import { removeIllegalChars, testDownloadHasFileExtension, testIsGuid } from '@/store/helperModules/common.module'
import publicIp from 'public-ip'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'Document',
  components: {
    AddPages,
    DocumentDeletePages,
    DocumentEmail,
    DocumentImage,
    DocumentSignatureHistory,
    IndexFieldList,
    Loading,
    MultipleLookups,
    PasswordPrompt,
    TheDocumentAttachments,
    TheDocumentData,
    TheDocumentHistory,
    TheDocumentLineItems,
    TheDocumentNotes,
    TheDocumentTasks,
    TheDocumentVersions,
  },
  async beforeRouteLeave(to, from, next) {
    await this.$store.dispatch('common/setIsSignatureDocLinkView', false)
    if (this.streamPDFRequestController != null) {
      this.streamPDFRequestController.abort()
    }
    if (this.isDirty && to.name !== 'Document') {
      this.routeNext = next
      this.showDirtyModal = true
    } else {
      next(true)
    }
  },
  data: function () {
    return {
      action: '',
      actionPassword: '',
      actionPasswordRequired: false,
      actionsFieldList: [],
      actionTaken: '',
      addInitialsDropListActive: false,
      addStampsDropListActive: false,
      allowPerformAction: true,
      annotation: {
        highlight: { type: 'highlight', color: 'rgba(254, 172, 0, 0.2)', subType: '' },
        redaction: {
          black: { type: 'redaction', color: 'black', subType: '' },
          white: { type: 'redaction', color: 'white', subType: '' },
        },
        signature: { type: 'signature', color: '', subType: '' },
        stamp: { type: 'stamp', color: '', subType: '' },
        text: { type: 'text', color: '', subType: '' },
      },
      base64DataPrefix: 'data:image/png;base64,',
      buttonOptions: [],
      confirmCompleteSignatureMessage: 'I agree to be legally bound by this document and the Vasion Terms of Service. Click on "I Agree" to finish signing this document.',
      contextApp: 'Annotations',
      currentAttributeFormID: {},
      currentPageNumber: this.$route.params.startingPage,
      currentPageRotation: 0,
      currentWorkflowAction: '',
      currentZonePage: 1,
      deleteAttachedFile: false,
      dirtyAttributeState: false,
      dirtyDueDateState: false,
      dirtyPriorityState: false,
      disableDeletePagesButton: true,
      document: {},
      documentGuid: '',
      dropdownLocation: '',
      additionalDocumentIds: '',
      dropDownOpen: false,
      signatureDocumentHistoryValues: [],
      signatureTabSelected: false,
      downloadLimitsReached: false,
      dueDate: null,
      dueDateLabel: 'Workflow Due Date',
      enableSignatureAnnotations: false,
      enteredPassword: '',
      errorLoadingDocument: false,
      firstRun: false,
      formName: '',
      geolocation: '',
      hasMoreThanOnePendingSignatureZone: false,
      hasPendingSignatureZones: false,
      hasSavedSignature: false,
      historyMode: '',
      indexPromptName: '',
      initialAttributeFieldListSize: 0,
      ipAddress: '',
      isDirtyFromTheDocumentData: false,
      isInSignatureApp: false,
      isLoading: true,
      isPdfLoading: true,
      isReviewer: false,
      isValid: true,
      isViewer: false,
      loaderBackgroundColor,
      loaderColor,
      loadingFullPage: false,
      localBackRoute: '',
      localFieldValues: [],
      modalTitle: '',
      missingCommentForReject: false,
      newComment: '',
      pages: [],
      pendingSignatureAnnotationIDsForCurrentUser: [],
      pendingSignatureZonesArray: [],
      pendingSignaturesPageNumbers: [],
      promptData: {},
      rightSidePanelMobile: true,
      selectedAttachedDocumentId: 0,
      selectedPagesForDelete: [],
      serverOptions: { process: this.processHandler },
      serverOptionsPages: { process: this.processPagesHandler },
      showActionPasswordDialog: false,
      showAddPagesModal: false,
      showAddSignatureDialog: false,
      showAttachedDocumentsDialog: false,
      showBurnAnnotations: false,
      showCompleteSignatureModal: false,
      showConfirmAttachedDocumentsMergeDeleteDialog: false,
      showDeletePagesConfirmDialog: false,
      showDeletePagesDialog: false,
      showDirtyModal: false,
      showDownloadDocumentDialog: false,
      showEllipsisIcon: false,
      showEllipsisToolbar: false,
      showEmailModal: false,
      showErrorLabel: false,
      showGroupModal: false,
      showHistoryDialog: false,
      showIndexPrompt: false,
      showLineItemsDialog: false,
      showLookupButton: false,
      showMultipleLookupDialog: false,
      showNotesDialog: false,
      showPrintModal: false,
      showPromptPassword: false,
      showRecallPrompt: false,
      showRejectSignatureModal: false,
      showRotateActions: false,
      showSnackbar: false,
      showUserSelectModal: false,
      showTasksDialog: false,
      showToolbar720: false,
      showUploadNewPageModal: false,
      showUploadNewVersionModal: false,
      showVersions: false,
      showViewModeMenu: false,
      showWorkflowList: false,
      signatureComment: '',
      signatureDocumentInitiatorUserID: null,
      signatureIconClickEvent: {},
      signingSignatureAnnotation: false,
      snackbarImage: true,
      snackbarSubTitle: 'Attributes Saved!',
      snackbarTitle: '',
      startWorkflowPayload: {},
      streamPDFData: '',
      streamPDFRequestController: null,
      tasks: [],
      thumbnails: [],
      totalSignatureZones: 0,
      uploadIndex: 0,
      userAssignPayload: {
        Values: [
          {
            Key: '',
            Value: {
              Values: [
                {
                  Key: '',
                  Value: '',
                },
              ],
            },
          },
        ],
      },
      userGroupAssignConfig: {
        type: '',
        userOnly: false,
        groupId: 0,
      },
      userPassword: '',
      viewerHeight: 1500,
      viewerWidth: 1210,
      viewMenuButtonConfig: {},
      viewMode: this.$route.name === 'DocumentData' ? 'data' : 'document',
      viewMode_Data: 'data',
      viewMode_Data_Icon: 'VasionColumnViewIcon',
      viewMode_Document: 'document',
      viewMode_Document_Icon: 'VasionCarouselHorizontalIcon',
      viewMode_PDF: 'pdf',
      viewMode_PDF_Icon: 'VasionPdfViewIcon',
      viewportWidth720: 875, // TODO: Change this to 720 once the zoom buttons are removed from the toolbar and match zeplin
      viewportWidth1280: 1438, // TODO: Change this to 1280 once the zoom buttons are removed from the toolbar and match zeplin
      workflow: {},
      workflowComments: [],
      workflowID: '',
      workflowList: [],
    }
  },
  computed: {
    arePagesReadyForUpload() {
      const pagesArray = this.$store.state.document.uploadedPagesToAdd
      let isReady = false
      if (this.$store.state.document.uploadedPagesPageNumber !== '' && this.$store.state.document.uploadedPagesPageNumber !== '') {
        isReady = true
      } else {
        isReady = false
      }
      if (pagesArray.length > 0) {
        isReady = pagesArray[0].fileName !== ''
      } else {
        isReady = false
      }
      return isReady
    },
    areRequiredFieldsFilled(){
      let result = true;
      if (this.indexFields)
      {
        this.indexFields.forEach(field => {
          if (field.required)
          {
            let found = false;
            if (this.localFieldValues)
            {
              for (let i = 0; i< this.localFieldValues.length; i++) {
                if (this.localFieldValues[i].name === field.value) {
                  found = true
                  if (this.localFieldValues[i].value.length === 0) {
                    result = false
                  }
                }
              }
            }
            if (!found) {
              result = false
            }
          }
        })
      }
      return result
    },
    annotationImageList() { return this.$store.state.document.annotationImageList },
    attachmentCount() { return !this.$store.state.document.linkedDocuments || this.$store.state.document.linkedDocuments?.tableRows?.length },
    attributeForms() {
      let returnArray = []
      returnArray = this.document.IndexForms?.Values?.map((v) => {
        return {
          name: v.Value,
          value: v.Key,
        }
      })

      return returnArray ?? []
    },
    canAddAnnotation() { return this.isNativeDocument === false && this.isReadOnly === false && this.$store.getters['common/canAddAnnotation'] },
    canAddPages() { return this.document?.CanAddPages },
    canAddOrDelete() {
      return !this.isReadOnly
        && this.isTiffOrPDF
        && this.canMergeDocuments
        && this.canAddPages
        && this.isInDocumentView
        && !this.isInSignatureMode
    },
    canAssignToWorkflow() { return this.$store.getters['common/canAssignToWorkflow'] },
    canBurnAnnotations() { return this.$store.getters['common/canBurnAnnotations'] },
    canDeletePages() { return this.isNativeDocument === false && this.isReadOnly === false && this.$store.getters['common/canDeletePages'] },
    canDownloadDocument() {
      return (!this.document.readOnlyFolderInfo || this.document.readOnlyFolderInfo.CanExport)
        && this.$store.getters['common/canDownloadDocument']
        && !this.downloadLimitsReached
    },
    canEditFieldData() { return this.$store.getters['common/canEditDocumentFieldValues'] },
    canEmailDocument() { return (!this.document.readOnlyFolderInfo || this.document.readOnlyFolderInfo.CanEmail) && this.$store.getters['common/canEmailDocument'] },
    canGeneratePDF() { return this.$store.getters['common/canGeneratePDF'] && this.isReadOnly === false },
    canMergeDocuments() { return this.isNativeDocument === false && this.isReadOnly === false && this.$store.getters['common/canMergeDocuments'] },
    canPerformDigitalSignature() { return this.isNativeDocument === false && this.isReadOnly === false && this.$store.getters['common/canPerformDigitalSignature'] },
    canPrintDocument() { return (!this.document.readOnlyFolderInfo || this.document.readOnlyFolderInfo.CanPrint) && this.$store.getters['common/canPrintDocument'] },
    canUploadNewVersionDocument() { return this.isReadOnly === false && this.$store.getters['common/canUploadNewVersionDocument'] },
    canViewAttachedDocuments() { return this.$store.getters['common/canViewLinkedDocuments'] },
    canViewDocumentHistory() { return this.$store.getters['common/canViewDocumentHistory'] },
    canViewDocumentNotes() { return this.$store.getters['common/canViewDocumentNotes'] },
    canViewDocumentVersions() { return this.$store.getters['common/canViewDocumentVersions'] },
    createdDate() { return !this.document || !this.document.CreatedDateUTC ? '' : this.document.CreatedDateUTC },
    currentPageData() {
      if (this.currentPageNumber <= 0 || !this.pages || this.pages.length < this.currentPageNumber) {
        return {
          pageNumber: 0,
          imageSource: '',
        }
      }

      return this.pages[this.currentPageNumber - 1]
    },
    disableZoomIn() {
      return this.zoomValue > 3
    },
    disableZoomOut() {
      return this.zoomValue <= 0.1
    },
    documentAttributeFields() {
      return !this.document || !this.document.IndexFormFields || !this.document.IndexFormFields.Values
        ? []
        : this.document.IndexFormFields.Values
    },
    documentAttributeFormID() { return !this.document || !this.document.FormId ? 0 : this.document.FormId },
    documentFolderID() { return !this.document || !this.document.FolderId ? 0 : this.document.FolderId },
    documentID() { return !this.document || !this.document.DocumentId ? 0 : this.document.DocumentId },
    documentIDString() { return this.documentID.toString() },
    documentName() { return !this.document || !this.document.DisplayName ? '' : this.document.DisplayName },
    documentTabClass() { return this.signatureTabSelected ? 'tab' : 'tab-selected' },
    downloadToken() { return !this.document || !this.document.DownloadToken ? '' : this.document.DownloadToken },
    fileExtension() {
      if (!this.document || !this.document.DocumentName) {
        return ''
      }

      const lastIndex = this.document.DocumentName.lastIndexOf('.')
      if (lastIndex < 0) {
        return ''
      }

      return this.document.DocumentName.substring(lastIndex)
    },
    filePondLabel() {
      return `<span class="filepond-drag">Drag File Here</span>
              <br /><br/>
              <span class="filepond-drag-or">or</span>
              <br /><br />
              <span class="filepond-label-action">Upload New Document</span>`
    },
    hasAdditionalDocumentIds() { return this.$route.params.additionalDocumentIds ? true : false },
    hasCertificate() { return false }, // TODO: The logic still needs to be layed out for determining if the document has a certificate
    historyModalTitle() {
      if (this.signatureTabSelected) {
        return `Signature History ${this.signatureDocumentGUID}`
      } else if (this.historyMode === 'history') {
          return 'Document History'
      }
      return ''
    },
    idWithPageNumber() { return `rotateMe${this.currentPageNumber}` },
    indexFields() { return this.$store.state.common.indexFields },
    isDirty() { return this.isDirtyFromTheDocumentData || this.dirtyPriorityState || this.dirtyDueDateState || this.dirtyAttributeState },
    isInDataView() { return this.viewMode === this.viewMode_Data },
    isInDocumentView() { return this.viewMode === this.viewMode_Document },
    isInPDFView() { return this.viewMode === this.viewMode_PDF },
    isInSignatureMode() { return this.document && this.document.pendingSignatureDocumentZones && this.document.pendingSignatureDocumentZones.length > 0 },
    isNativeDocument() { return !this.document || !this.document.FileDetail || this.document.FileDetail.Type !== 0 },
    isReadOnly() { return this.document.IsReadOnly },
    isSignatureDocLinkView() { return this.$store.state.common.isSignatureDocLinkView },
    isTiffOrPDF() {
      const localFileExtension = this.fileExtension.toLowerCase()
      return localFileExtension === '.tif' || localFileExtension === '.tiff' || localFileExtension === '.pdf'
    },
    maxImageWidth() { return window.innerWidth - this.subtractWindowWidth },
    modifiedDate() { return !this.document || !this.document.ModifiedDateUTC ? '' : this.document.ModifiedDateUTC },
    noteCount() { return !this.$store.state.document.notes || this.$store.state.document.notes.tableRows.length },
    pageReadyToLoad() { return (this.priorities?.length && this.documentID > 0 && (!this.document.Workflow || this.priority)) },
    pendingSignatureZonesCounter() {
      return this.$store.state.document.pendingSignatureAnnotationIDsForCurrentUser
        ? this.$store.state.document.pendingSignatureAnnotationIDsForCurrentUser.length
        : 0
    },
    priorities() { return this.$store.state.workflow.priorities },
    priority: {
      get: function () {
        return this.$store.state.document.priority ? this.$store.state.document.priority : {
        priorityID: 1,
        priorityName: 'High',
      }
      },
      set: function (newValue) {
        this.$store.dispatch('document/setDocumentPriority', newValue)
      },
    },
    priorityValue() { return this.$store.state.document.priority ? this.$store.state.document.priority.priorityID : 1 },
    refreshThumbnailKey() { return this.$store.state.document.refreshThumbnailKey },
    saveButtonActive() { return ((this.dirtyAttributeState || this.dirtyDueDateState || this.dirtyPriorityState) && this.isValid && this.areRequiredFieldsFilled )},
    saveAndNextButtonActive() { return ((this.dirtyAttributeState || this.dirtyDueDateState || this.dirtyPriorityState) && this.isValid) },
    selectedAnnotation() { return this.$store.state.digitalSignature.selectedAnnotation },
    signatureTabClass() { return this.signatureTabSelected ? 'tab-selected' : 'tab' },
    showDeletePagesBtn() { return this.canDeletePages && this.isInDocumentView && !this.isInSignatureMode && !this.isReadOnly && this.isTiffOrPDF },
    showStartWorkflowButton() {
      return !this.isReadOnly
      && this.canAssignToWorkflow
      && this.isInDocumentView
      && !this.isInSignatureMode
      && !this.hasPendingSignatureZones
      && !this.showWorkflowDiv
      && !this.isInSignatureApp
    },
    showSaveButton() { return this.isReadOnly === false && this.canEditFieldData },
    showWorkflowDiv() { return this.workflow && this.workflow.InWorkflow },
    signatureDocumentGUID() { return !this.document || !this.document.signatureDocumentGUID ? '' : this.document.signatureDocumentGUID },
    signatureZonesCompleted() {
      return this.isInSignatureMode
        && this.hasPendingSignatureZones
        && this.pendingSignatureZonesCounter === 0
    },
    signedSignatureZones() { return this.totalSignatureZones - this.pendingSignatureZonesCounter },
    subtractWindowWidth() {
      let result = 730
      if (this.isNativeDocument) {
        result -= 200
      }

      return result
    },
    supportPDFViewer() { return this.document && this.document.LocalPDFName },
    taskCount() { return !this.document || !this.document.Tasks || !this.document.Tasks.Values ? 0 : this.document.Tasks.Values.length },
    totalPages() { return !this.document || !this.document.FileDetail || !this.document.FileDetail.PageCount ? 1 : this.document.FileDetail.PageCount },
    UserID() { return this.$store.state.common.userID },
    userInitials() { return this.$store.state.digitalSignature.userInitials ? `${this.base64DataPrefix}${this.$store.state.digitalSignature.userInitials}` : '' },
    userSignature() { return this.$store.state.digitalSignature.userSignature ? `${this.base64DataPrefix}${this.$store.state.digitalSignature.userSignature}` : '' },
    versionCount() {
      return !this.document || !this.$store.state.document.documentVersionData?.tableRows
        ? 0
        : this.$store.state.document.documentVersionData?.tableRows.length
    },
    VersionNumber() { return !this.document || !this.document.VersionNumber ? '' : this.document.VersionNumber },
    viewModeIcon() {
      switch (this.viewMode) {
        case this.viewMode_PDF:
          return this.viewMode_PDF_Icon;
        case this.viewMode_Data:
          return this.viewMode_Data_Icon;
        default:
          return this.viewMode_Document_Icon;
      }
    },
    workflowApprover() {
      const newFields = this.documentAttributeFields ? this.documentAttributeFields.filter(f => f.FieldName === 'Workflow_Approver') : []
      return newFields && newFields.length > 0 ? newFields[0].TextValue : ''
    },
    workflowInitiator() {
      const newFields = this.documentAttributeFields ? this.documentAttributeFields.filter(f => f.FieldName === 'Workflow_Initiator') : []
      return newFields && newFields.length > 0 ? newFields[0].TextValue : ''
    },
    workflowStatus() {
      const newFields = this.documentAttributeFields ? this.documentAttributeFields.filter(f => f.FieldName === 'Workflow_Status') : []
      return newFields && newFields.length > 0 ? newFields[0].TextValue : ''
    },
    zoomValue() { return this.$store.state.document.zoomValue },
  },
  watch: {
    currentAttributeFormID: async function () {
      this.showLookupButton = false
      if (!this.currentAttributeFormID || this.currentAttributeFormID.value <= 0) {
        this.$store.dispatch('common/getIndexFieldsForForm', 0)
        this.showLookupButton = false
        return
      }
      this.setFormName()
      this.dirtyAttributeState = true
      this.$store.dispatch('common/getIndexFieldsForForm', this.currentAttributeFormID.value)
      this.$store.dispatch('document/getDefaultLookupId', this.currentAttributeFormID.value).then((result) => {
        if (result && result.Value) {
          this.showLookupButton = Number(result.Value) > 0
        }
      })
      if (!this.firstRun) {
        this.firstRun = true
        this.dirtyAttributeState = false
      }
    },
    indexFields: function () {
      this.initialAttributeFieldListSize = 0
      if (this.indexFields) {
        this.indexFields.map((field) => {
          const foundField = this.documentAttributeFields.find((f) => {
            return f.FieldName === field.value
          })

          field.filterValue = this.getValueFromDocField(foundField)
          if (field.filterValue !== '' && field.filterValue !== null) {
            this.initialAttributeFieldListSize += 1
          }
          return true
        })
      }
      this.localFieldValues = []
    },
    pendingSignatureZonesCounter: async function (newCounter, oldCounter) {
      if (this.isInSignatureMode && this.signatureDocumentGUID && newCounter < oldCounter) {
        this.saveSignatureDocumentHistory('Signed')
      }
      if (this.pendingSignatureZonesCounter === 0) {
        this.rightSidePanelMobile = false
      }
    },
    selectedAnnotation: async function () {
      if (!this.selectedAnnotation || !this.selectedAnnotation.id || !this.selectedAnnotation.src) {
        return
      }

      const canSignAnnotation = this.pendingSignatureZonesArray.some(e => e.annotationID === this.selectedAnnotation.id && String(e.signatureUserID) === String(this.UserID))
      if (!canSignAnnotation || !this.selectedAnnotation?.propertiesSubType) {
        return
      }

      let signatureImage = null
      if (this.selectedAnnotation?.propertiesSubType === 'timestamp') {
        this.isLoading = true
        this.signingSignatureAnnotation = true
        const response = await this.$store.dispatch('digitalSignature/getDateAsImage', this.selectedAnnotation?.includeTime)
        if (!response) {
          return
        }

        const dateImage = `data:image/png;base64,${response}`
        await this.addNewSignature(dateImage)
        this.isLoading = false
        return
      } else if (this.selectedAnnotation?.propertiesSubType === 'text') {
        this.addNewAnnotation({ color: '', subType: '', type: 'text', applyOnZone: true })
        const copySelected = this.selectedAnnotation
        copySelected.isSigned = true
        this.signingSignatureAnnotation = false
        this.addInitialsDropListActive = false
        await this.$store.dispatch('digitalSignature/setSelectedAnnotation', copySelected)
        return
      }

      if (this.selectedAnnotation?.propertiesSubType === 'initial') {
        signatureImage = this.userInitials
      } else {
        signatureImage = this.userSignature
      }

      if (!signatureImage) {
        this.hasSavedSignature = false
      } else {
        this.hasSavedSignature = true
      }

      if (!this.enableSignatureAnnotations) {
        await this.checkForUserPassword()
      }
      if (this.enableSignatureAnnotations) {
        this.signingSignatureAnnotation = true
        this.showAddSignatureDialog = true
      }
    },
    signatureDocumentGUID: async function (newSignatureDocumentGUID, oldSignatureDocumentGUID) {
      if (!newSignatureDocumentGUID || newSignatureDocumentGUID === oldSignatureDocumentGUID || !this.isInSignatureMode) {
        return
      }

      // getGeoLocation is an async call which prompts the User to allow access to their geo location
      // the User can ignore that prompt, which is fine, but if we wait for a response, we'll never log that the document was viewed
      // so instead, we're initiating here with the hope they grant access for subsequent calls
      // but we just log the "Viewed" with their IP Address
      this.getGeoLocation()

      publicIp.v4().then(ip => {
        this.ipAddress = ip
        this.saveSignatureDocumentHistory('Viewed')
      })
    },
  },
  async created() {
    // Make calculations for toolbar design
    let viewportWidth = window.innerWidth
    this.showEllipsisIcon = viewportWidth <= this.viewportWidth1280
    this.showToolbar720 = viewportWidth <= this.viewportWidth720
    window.addEventListener('resize', () => {
      viewportWidth = window.innerWidth;
      this.showEllipsisIcon = viewportWidth <= this.viewportWidth1280
      this.showToolbar720 = viewportWidth <= this.viewportWidth720
      if (!this.showEllipsisIcon) {
        this.showEllipsisToolbar = false
      }
    })
    this.$material.locale.dateFormat = 'MM/dd/yyyy'
    this.localBackRoute = !this.$store.state.document.backRoute ? '/' : this.$store.state.document.backRoute
    this.additionalDocumentIds = this.hasAdditionalDocumentIds ? this.$route.params.additionalDocumentIds.split(',') : []
      await Promise.all([
        this.reloadDocument(this.$route.params.documentID),
        this.$store.dispatch('document/resetZoomValue'),
        this.$store.dispatch('digitalSignature/getUserSignatureForStore'),
        this.$store.dispatch('digitalSignature/getUserInitialsForStore'),
        this.$store.dispatch('document/getAnnotationImageList'),
        this.$store.dispatch('workflow/getWorkflowPriorities'),
    ])
    if (this.userSignature.length > 0 || this.userInitials.length > 0) {
      this.hasSavedSignature = true
    }
    this.determineDownloadLimitsReached()
  },
  methods: {
    addNewAnnotation(config) { this.$store.dispatch('document/setAnnotationConfig', config) },
    async addNewSignature(signature) {
      this.showAddSignatureDialog = false
      this.addInitialsDropListActive = false

      if (this.signingSignatureAnnotation) {
        this.annotation.signature.subType = 'signature'
        this.addSignature(signature)

        // overwrite default annotation that was set in addSignature
        this.addNewAnnotation({ color: '', subType: '', type: 'signature', applyOnZone: true })

        const copySelected = this.selectedAnnotation
        copySelected.isSigned = true
        this.signingSignatureAnnotation = false
        this.addInitialsDropListActive = false
        await this.$store.dispatch('digitalSignature/setSelectedAnnotation', copySelected)
      } else {
        this.annotation.signature.subType = 'signature'
        this.addSignature(signature)
        this.addInitialsDropListActive = false
      }
    },
    addSavedSignature() {
      if (this.isSignatureDocLinkView || this.isInSignatureMode) {
        this.toggleShowAddSignatureDialog()
      }
      this.annotation.signature.subType = 'signature'
      this.addSignature(this.userSignature)
      this.addInitialsDropListActive = false
    },
    addSavedSignatureInitials() {
      if (this.isSignatureDocLinkView || this.isInSignatureMode) {
        this.toggleShowAddSignatureDialog()
      }
      this.annotation.signature.subType = 'initial'
      this.addSignature(this.userInitials)
      this.addInitialsDropListActive = false
    },
    addNewStamp(stamp) {
      this.annotation.stamp.subType = 'stamp'
      this.addStampsDropListActive = false

      this.$store.dispatch('document/activeStamp', `${this.base64DataPrefix}${stamp.base64String}`)
    },
    addNewWhiteRedaction() { this.$refs.mainImage.placeWhiteRedaction() },
    addSignature(img) {
      this.$store.dispatch('document/activeSignature', img)
      this.addNewAnnotation(this.annotation.signature)
    },
    async attributeFieldLookUp() {
      const FieldValues = {
        Values: this.getLocalIndexFieldValuePairs(),
      }
      this.initialAttributeFieldListSize = 0

      const results = await this.$store.dispatch('attributeForm/attributeFieldLookUp', {
        IndexFormId: this.currentAttributeFormID ? this.currentAttributeFormID.value : this.document.FormId,
        FieldValues,
      })

      switch (results.Rows?.length) {
        case 0:
          this.showSnackbar = true
          this.snackbarTitle = 'ERROR'
          this.snackbarSubTitle = 'No Results Found.'
          this.snackbarImage = false
          return
        case 1:
          this.$refs.attributeFieldsComponent.updateFieldsFromLookup(results)
          this.dirtyAttributeState = true
          break
        default:
          this.localFieldValues = results
          this.showMultipleLookupDialog = true
          break
      }
    },
    async backToPreviousView() {
      await this.$store.dispatch('document/setBackRoute', '/')
      this.$router.push({ path: this.localBackRoute })
    },
    changeModalClass(val) {
      this.dropDownOpen = val
    },
    async checkForUserPassword(event) {
      this.signatureIconClickEvent = event
      const response = await this.$store.dispatch('digitalSignature/getUserInfo')
      this.userPassword = response.SignaturePassword

      if (response?.SignaturePassword !== '' && !this.enableSignatureAnnotations) {
        this.showPromptPassword = true
      } else {
        this.enableSignatureAnnotations = true
      }
    },
    async clickSignatureToolbarButton($event) {
      if (!this.addInitialsDropListActive) {
        await this.checkForUserPassword($event);
      }

      if (this.enableSignatureAnnotations) {
        this.toggleShowAddSignatureList($event)
      }
    },
    async comparePasswords(event) {
      if (this.userPassword === this.enteredPassword) {
        this.showPromptPassword = false
        this.enableSignatureAnnotations = true
        if (!this.isInSignatureMode) {
          this.toggleShowAddSignatureList(event)
        } else {
          const copySelected = this.selectedAnnotation
          await this.$store.dispatch('digitalSignature/setSelectedAnnotation', {})
          await this.$store.dispatch('digitalSignature/setSelectedAnnotation', copySelected)
          this.$store.dispatch('digitalSignature/setDrawSignature', true)
        }
      } else {
        this.showSnackbar = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'The password was incorrect'
        this.snackbarImage = false
      }
    },
    async completeSignatureDocument() {
      this.showCompleteSignatureModal = false
      this.isLoading = true

      const payload = {
        documentId: this.documentID,
      }

      await Promise.all([
        this.$store.dispatch('document/approveSignatureDocument', payload),
        this.saveSignatureDocumentHistory('Completed'),
        this.saveSignatureDocumentComment(),
      ])
      this.isLoading = false

      if (this.isSignatureDocLinkView) {
        this.$router.push({
          name: 'TheSignatureConfirmation',
          params: {
            confirmationMessage: 'Document Completed',
            confirmationSubMessage: 'Thank you for using Vasion',
          },
        })
      } else {
        this.backToPreviousView()
      }
    },
    async confirmBurnAnnotations() {
      this.isLoading = true

      const response = await this.$store.dispatch('document/burnAnnotations', { Value: this.documentIDString })
      this.toggleBurnAnnotations()
      this.isLoading = false
      if (response.ResultStatus === 1 && response.Versions) {
        this.showSnackbar = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Annotations burned successfully'
        this.snackbarImage = true
        this.reloadDocument(this.documentID)
      } else {
        this.showSnackbar = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'There was an error burning the annotations.'
        this.snackbarImage = false
      }
    },
    clearPageSelectionAndInsertOption() {
      this.$store.dispatch('document/uploadedPagesInsertOption', '')
      this.$store.dispatch('document/uploadedPagesPageNumber', this.currentPageNumber)
      this.$store.dispatch('document/clearUploadedPagesToAdd')
      this.showAddPagesModal = false;
    },
    closeDocumentEmail() { this.showEmailModal = false },
    closeViewModeMenu() { this.showViewModeMenu = false },
    async createPDFVersionClick() {
      this.isLoading = true
      await this.$refs.documentData.createVersionAttributeFormPDF()
      this.isLoading = false
    },
    async createUserAndGroupData(userList, groupList) {
      const newUserList = []
      const newGroupList = []

      if (this.userGroupAssignConfig.type === 'prmt_ChooseFromGroup'
          && this.userAssignPayload.Values[0]?.Value.Values[0]?.Key === 'cfg_GroupID'
          && this.userAssignPayload.Values[0]?.Value.Values[0]?.Value !== '') {
            this.userGroupAssignConfig.groupId = Number(this.userAssignPayload.Values[0]?.Value.Values[0]?.Value)
        userList = await this.$store.dispatch('security/getGroupChildren', this.userGroupAssignConfig.groupId)
      }

      Object.keys(userList).map((key) => {
        let user
        if (userList && userList.length > 0 && userList[key]) {
          user = userList[key]

          newUserList.push({
            name: `${user.FirstName || ''} ${user.LastName || ''}`,
            value: Number(user.UserId),
            username: user.Username,
          });
        }
        return true;
      });

      Object.keys(groupList).map((key) => {
        let group
        if (groupList.length > 0 && groupList[key]) {
          group = groupList[key]

          newGroupList.push({
            name: group.Name,
            value: Number(group.GroupId),
          });
        }
        return true;
      });

      const dataList = {
        userList: newUserList,
        groupList: newGroupList,
      }

      return dataList
    },
    async commentButtonClicked() {
      const payload = {
        documentID: this.documentID,
        stepID: this.$route.params.workflowStepId,
        commentText: this.newComment,
      }
      const response = await this.$store.dispatch('workflow/addWorkflowComment', payload)
      if (response === 'True') {
        this.showSnackbar = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Comment was saved successfully!'
        this.snackbarImage = true
      } else {
        this.showSnackbar = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'There was an error saving the comment'
        this.snackbarImage = false
      }
      this.newComment = ''
    },
    async continueWorkflowSelected(workflowID) {
      this.selectedWorkflowID = workflowID

      if (this.selectedWorkflowID <= 0) {
        this.showWorkflowList = false
        return
      }
      this.startWorkflowForSelectedDocuments()
    },
    async deletePages() {
      const payload = {
        documentID: this.documentID,
        pageNumbers: this.selectedPagesForDelete,
      }
      const response = await this.$store.dispatch('document/deleteDocumentPages', payload)
      if (response) {
        this.showSnackbar = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = response
        this.snackbarImage = false
      } else {
        this.showDeletePagesDialog = false
        this.reloadDocument(this.$route.params.documentID)
      }
      this.showDeletePagesConfirmDialog = false
    },
    async determineDownloadLimitsReached() {
      this.downloadLimitsReached = false

      if (await testIsGuid(this.documentGuid)) {
        const countData = await this.getDocumentDownloadCount(this.documentGuid)
        if (countData && countData.data && (countData.data.maxDownloads > 0 && (countData.data.currentDownloads >= countData.data.maxDownloads))) {
          this.downloadLimitsReached = true
        }
      }
    },
    dirtyModalCancelClick () {
      this.showDirtyModal = false
    },
    dirtyModalOKClick () {
      this.routeNext()
    },
    async downloadDocument(downloadOptions) {
      const downloadPayload = {
        T: this.downloadToken,
        BurnAnnotations: downloadOptions ? downloadOptions.burnAnnotations : false,
        UserID: this.UserID,
        IncludeWatermark: false,
        IncludeHistory: downloadOptions ? downloadOptions.includeSigDocHistory : false,
        ExtractPages: downloadOptions ? downloadOptions.extractPages : '',
        ConvertToPDF: downloadOptions ? downloadOptions.convertToPDF : false,
        StreamPDF: '',
      }

      this.showDownloadDocumentDialog = false
      this.isLoading = true
      const downloadResult = await this.$store.dispatch('document/downloadVasionDocument', downloadPayload)

      if (downloadResult && !this.downloadLimitsReached) {
        const newFileName = await removeIllegalChars(this.documentName)

        // eslint-disable-next-line
        const fileDownload = require('js-file-download')
        const localFileExtension = downloadPayload.ConvertToPDF === true ? '.pdf' : this.fileExtension

        testDownloadHasFileExtension(downloadResult, newFileName, localFileExtension)

        if (await testIsGuid(this.documentGuid)) {
          await this.incrementDocumentDownloadCount(this.documentGuid)
          this.determineDownloadLimitsReached()
        }
      } else {
        this.snackbarText = 'Unable to download the Document'
      }

      this.isLoading = false
    },
    async downloadDocumentClick() {
      if (this.canDownloadDocument === false || this.downloadToken === '') {
        return
      }
      if (!this.isInDocumentView) {
        this.$refs.documentData.downloadAttributeFormDocument(this.documentName)
        return
      }

      if (this.isTiffOrPDF === false) {
        this.downloadDocument()
      } else {
        this.showDownloadDocumentDialog = !this.showDownloadDocumentDialog
      }
    },
    emailButtonClick() { this.showEmailModal = true },
    async exportSigDocumentToPDF() {
     await this.$store.dispatch('document/exportSignatureDocumentHistory', this.documentID)
     this.showHistoryDialog = !this.showHistoryDialog
    },
    async getDocumentDownloadCount(documentId) {
      const documentDownloadData = await this.$store.dispatch('document/getDocumentDownloadCount', documentId)
      return documentDownloadData
    },
    async getDocumentDetails(documentId) {
      if (await testIsGuid(documentId)) {
        const canPerformWFAction = await this.$store.dispatch('workflow/canPerformWFAction', documentId)
        if (canPerformWFAction === 'True') {
          this.$store.dispatch('mainViews/changeActiveSubApp', 'workspace')
          this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
        } else if (canPerformWFAction === 'False') {
          this.$store.dispatch('mainViews/changeActiveSubApp', 'storage')
        }
        this.documentGuid = documentId
        return this.$store.dispatch('document/getDocumentDetailsByGuid', this.documentGuid)
      } else if (this.$route.params.version) {
        return this.$store.dispatch('document/getDocumentDetailsWithVersion', { documentID: documentId, version: this.$route.params.version })
      } else {
        return this.$store.dispatch('document/getDocumentDetails', { documentID: documentId, workflowStepID: this.$route.params.workflowStepId })
      }
    },
    async getExternalIpAndGeoLocation() {
      const ip = await publicIp.v4()
      this.ipAddress = ip
      await this.getGeoLocation()
    },
    getGeoLocation() {
      if (!navigator.geolocation) {
        return
      }

      navigator.geolocation.getCurrentPosition(pos => {
        const lat = pos.coords.latitude
        const lng = pos.coords.longitude
        const acc = pos.coords.accuracy
        this.geolocation = `${lat}, ${lng}, ${acc}`
      }, err => {
        console.error(err)
      }, { timeout: 1000 })
    },
    getLocalIndexFieldValuePairs() {
      if (!this.localFieldValues) {
        return []
      }

      return this.localFieldValues.map(e => {
        if (Object.prototype.toString.call(e.value) === '[object Array]') {
          const dropdownValues = []
          e.value.forEach(v => {
            dropdownValues.push(v.name)
          })
          const dropDownSingleString = dropdownValues.join(';')
          return {
            Key: e.name,
            Value: dropDownSingleString,
          }
        } else {
          return {
            Key: e.name,
            Value: e.value,
          }
        }
      })
    },
    getStringFromIndexValue(indexValue) {
      let text = ''

      if (!indexValue) {
        return text
      }

      if (Array.isArray(indexValue)) {
        indexValue.map((value) => {
          if (value.name) {
            // .value is an array of objects, each with it's own name and value properties.
            // This is in support of multi-select VasionDroplists
            text += `${value.name};`
          } else {
            // .value is a string array ...
            text += `${value};`
          }
          return true
        })

        if (text !== '') {
          // remove the trailing semi-colon
          text = text.substring(0, text.length - 1)
        }
      } else {
        text = indexValue
      }

      return text
    },
    getValueFromDocField(docField) {
      let ret = ''

      try {
        if (docField) {
          if (docField.Control === 2) {
            ret = docField.DropDownSelectedValues.Values
          } else if (docField.Control === 3) {
            ret = docField.DateValueUTC
          } else {
            ret = docField.TextValue
          }
        }
      } catch {
        ret = ''
      }

      return ret
    },
    goToNextZone() {
      if (this.currentZonePage < this.pendingSignaturesPageNumbers.length) {
        this.currentZonePage = this.pendingSignaturesPageNumbers.findIndex(i => {
          return i > this.currentPageNumber
        })
        if (this.currentZonePage === -1) {
          this.currentZonePage = 0
        }
        this.goToPage(this.pendingSignaturesPageNumbers[this.currentZonePage])
      }
    },
    goToPage(pageNumber) {
      if (typeof pageNumber !== 'undefined' && pageNumber > 0 && pageNumber <= this.totalPages) {
        this.currentPageNumber = pageNumber
        this.$store.dispatch('document/uploadedPagesPageNumber', this.currentPageNumber)
      }
    },
    goToPageEmit(payload) {
      this.goToPage(payload.pageNumber)
    },
    goToPreviousZone() {
      let zonePageNumbers = []
      if (this.currentZonePage > 0) {
        this.pendingSignaturesPageNumbers.forEach(i => {
          if (i < this.currentPageNumber) {
            zonePageNumbers.push(i)
          }
        })
        this.currentZonePage = zonePageNumbers.length - 1
      } else {
        this.currentZonePage = this.pendingSignaturesPageNumbers.length - 1
      }
        this.goToPage(this.pendingSignaturesPageNumbers[this.currentZonePage])
    },
    handleForwardReassign(selected) {
      const { value, type } = selected
      const cugType = type === 'users' ? 'cug_User' : 'cug_Group'

      // prmt_ChooseGroupUser = Need to Reassign or Forward
      // prmt_ChooseFromGroup = Need to assign to a user within a specific group
      if (this.userGroupAssignConfig.type === 'prmt_ChooseFromGroup') {
        this.promptData = {
          Values: [
            {
              Key: this.userGroupAssignConfig.type,
              Value: {
                Values: [
                  {
                    Key: 'cfg_UserID',
                    Value: value,
                  },
                ],
              },
            },
          ],
        }
      } else {
        this.promptData = {
          Values: [
            {
              Key: this.userGroupAssignConfig.type,
              Value: {
                Values: [
                  {
                    Key: 'cug_SelectedType',
                    Value: cugType,
                  },
                  {
                    Key: 'cug_SelectedID',
                    Value: value,
                  },
                ],
              },
            },
          ],
        }
      }
      this.workflowAction(this.action)
    },
    handleIndexPrompt() {
      const indexFieldValues = this.getLocalIndexFieldValuePairs()
      this.promptData = {
        Values: [
          {
            Key: this.indexPromptName,
            Value: {
              Values: indexFieldValues,
            },
          },
        ],
      }
      this.showIndexPrompt = !this.showIndexPrompt
      this.workflowAction(this.action)
    },
    handlePrintClick() {
      if (this.isInDocumentView) {
        this.showPrintModal = !this.showPrintModal
      } else {
        this.printAttributeForm()
      }
    },
    async handleUserSelected(user) {
      if (!user || !user.value) {
        this.showUserSelectModal = false
        return
      }
      this.startWorkflowPayload.documentID = this.documentID.toString()
      this.startWorkflowPayload.promptData.prmt_ChooseFromGroup.cfg_UserID = user.value

      this.continueWorkflowSelected()
    },
    async incrementDocumentDownloadCount(documentId) {
      const payload = {
        Value: documentId,
      }
      return this.$store.dispatch('document/incrementDownloadCounter', payload)
    },
    loadThumbnails() {
      const length = this.totalPages
      this.thumbnails = [length]

      for (let i = 0; i < length; i += 1) {
        const pageNumber = (i + 1)
        this.thumbnails[i] = {
          pageNumber: pageNumber,
        }
      }
    },
    mainImageLoaded(imageData) {
      if ((this.viewerHeight > this.viewerWidth && imageData.height < imageData.width)
        || (this.viewerHeight < this.viewerWidth && imageData.height > imageData.width)) {
          // need to flip the bounds
          const temp = this.viewerHeight
          this.viewerHeight = this.viewerWidth
          this.viewerWidth = temp
        }

      if (this.viewerHeight > imageData.height || this.viewerWidth > imageData.width) {
        this.viewerHeight = imageData.height
        this.viewerWidth = imageData.width
      }

      while (this.viewerWidth > this.maxImageWidth) {
        this.zoomOut()
      }
    },
    markClean() { this.dirtyAttributeState = false },
    markDirty(from) {
      if (from === 'fromTheDocumentData') {
        this.isDirtyFromTheDocumentData = true
      }
      this.dirtyAttributeState = true
    },
    markIsValid() { this.isValid = true },
    markNotValid() { this.isValid = false },
    permanentlyRotateLeft() {
      this.rotateLeft()
      this.saveRotate()
    },
    permanentlyRotateRight() {
      this.rotateRight()
      this.saveRotate()
    },
    printAttributeForm() { this.$refs.documentData.printAttributeForm() },
    async printSelectedDocuments(settings) {
      this.showPrintModal = !this.showPrintModal
      const payload = {
        T: [this.documentID],
        BurnAnnotations: settings.burnAnnotations,
        ConvertToPDF: true,
        IncludeHistory: settings.includeSigDocHistory,
        UserID: this.UserID,
      }
      const documentsForPrint = await this.$store.dispatch('workflow/downloadVasionDocumentsForPrint', payload)

      // eslint-disable-next-line
      const printJS = require('print-js')

      if (!documentsForPrint.Value) {
        this.showSnackbar = true
        this.snackbarTitle = 'Error!'
        this.snackbarSubTitle = 'The file(s) could not be printed.'
        this.snackbarImage = false
      } else if (documentsForPrint) {
        printJS({
          printable: documentsForPrint.Value, type: 'pdf', showModal: true, onPrintDialogClose: () => { this.$store.dispatch('workflow/deletePrintedFile', documentsForPrint) },
        });
      }
    },
    // eslint-disable-next-line
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      try {
        this.uploadedFileName = file.name
        let fileDataString = await toBase64(file)
        const indexOf = fileDataString.indexOf(';base64,')
        if (indexOf >= 0) {
          fileDataString = fileDataString.substring(indexOf + 8)
        }

        const payload = {
          DocumentID: this.documentID,
          FileName: file.name,
          Base64FileString: fileDataString,
        }
        const response = await this.$store.dispatch('storage/uploadNewVersion', payload)
        if (!response) {
          this.showSnackbar = true
          this.snackbarTitle = 'ERROR'
          this.snackbarSubTitle = 'Unable to upload new version.'
          this.snackbarImage = false
        } else {
          progress(true, 0, 1024);
          load(file.name)
          this.showUploadNewVersionModal = !this.showUploadNewVersionModal
          this.reloadDocument(this.$route.params.documentID)
        }
      return {
        abort: () => {
          if (abort !== undefined) {
            abort();
          }
        },
      }
      } catch (err) {
        console.warn(err)
      }
    },
    // eslint-disable-next-line
    processPagesHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      try {
        this.errorText = ''
        const TiffIndex = file.name.toLowerCase().indexOf('.tiff')
        const TifIndex = file.name.toLowerCase().indexOf('.tif')
        const pdfIndex = file.name.toLowerCase().indexOf('.pdf')
        if (TiffIndex < 0 && TifIndex < 0 && pdfIndex < 0) {
          const errorText = 'Only Tiff, Tif, and pdf file types are supported. Please try uploading one of those file types.'
          this.showSnackbar = true
          this.snackbarTitle = 'ERROR'
          this.snackbarSubTitle = 'Unable to upload this file type'
          this.snackbarImage = false
          file = {}
          console.warn(errorText)
          this.errorText = errorText
          this.toggleUploadAndPage()
        } else {
          const uploadedImage = await toBase64(file)
          const stringIndex = uploadedImage.indexOf('base64,')
          const payload = {
            fileName: file.name,
            base64String: uploadedImage.substring(stringIndex + 7),
            uploadIndex: this.uploadIndex,
          }
          this.$store.dispatch('document/addPageToList', payload)
          this.uploadNewPageToggle()
          progress(true, 0, 1024);
          load(file.name)
        }
        this.showUploadDialog = false
        return {
          abort: () => {
            if (abort !== undefined) {
              abort();
            }
          },
        }
      } catch (e) {
        console.warn(e)
      }
    },
    async recallSignatureDocument() {
      this.showRecallPrompt = false
      this.isLoading = true

      const payload = {
        documentID: this.documentID,
        userIPAddress: this.ipAddress ? this.ipAddress : '',
        userGeoLocation: this.geolocation ? this.geolocation : '',
      }

      const response = await this.$store.dispatch('document/recallSignatureDocument', payload)
      this.isLoading = false

      if (response.Value === 'True') {
        this.showRecallSnackbar(true)
        setTimeout(() => {
          if (this.isSignatureDocLinkView) {
            this.$router.push({
              name: 'TheSignatureConfirmation',
              params: {
                confirmationMessage: 'Document Recalled',
                confirmationSubMessage: 'Thank you for using Vasion',
              },
            })
          } else {
            this.backToPreviousView()
          }
        }, 1500)
      } else { this.showRecallSnackbar(false) }
    },
    async rejectSignatureDocument() {
      this.showRejectSignatureModal = false
      this.isLoading = true

      const payload = {
        documentId: this.documentID,
      }

      const response = await this.$store.dispatch('document/rejectSignatureDocument', payload)

      if (response) {
        await Promise.all([
          this.saveSignatureDocumentHistory('Rejected'),
          this.saveSignatureDocumentComment(),
        ])

        this.isLoading = false
        this.showRejectSnackbar(true)

        setTimeout(() => {
          if (this.isSignatureDocLinkView) {
            this.$router.push({
              name: 'TheSignatureConfirmation',
              params: {
                confirmationMessage: 'Document Rejected',
                confirmationSubMessage: 'Thank you for using Vasion',
              },
            })
          } else {
            this.backToPreviousView()
          }
        }, 1500)
      } else {
        this.isLoading = false
        this.showRejectSnackbar(false)
      }
    },
    async reloadDocument(documentID) {
      this.currentPageRotation = 0
      this.currentPageNumber = parseInt(this.$route.params.startingPage, 10)
      this.$store.dispatch('document/uploadedPagesPageNumber', this.currentPageNumber)
      this.document = {}
      this.thumbnails = []
      this.localFieldValues = []
      this.pages = []
      this.currentAttributeFormID = {
        value: 0,
        name: '',
      }
      this.viewMenuButtonConfig = {}
      this.streamPDFData = ''

      this.getDocumentDetails(documentID).then((data) => {
        if (!data || !data.DocumentId) {
          this.errorLoadingDocument = true
          this.isLoading = false
          return
        }

        this.errorLoadingDocument = false
        this.document = data
        this.signatureDocumentInitiatorUserID = data.signatureDocumentInitiatorUserID

        this.$store.dispatch('document/getDocumentVersions', this.document.DocumentId)
        this.$store.dispatch('document/getDocumentPriority', this.document.DocumentId)

        this.viewMenuButtonConfig = {
          buttons: [
            {
              id: this.viewMode_Document,
              name: 'Document View',
              icon: this.viewMode_Document_Icon,
            },
            {
              id: this.viewMode_Data,
              name: 'Data View',
              icon: this.viewMode_Data_Icon,
            },
          ],
        }

        if (this.supportPDFViewer) {
          this.viewMenuButtonConfig.buttons.push({
            id: this.viewMode_PDF,
            name: 'PDF View',
            icon: this.viewMode_PDF_Icon,
          })

          this.streamPDFRequestController = new AbortController()
          var payload = {
            documentID: this.document.LocalPDFName,
            requestController: this.streamPDFRequestController
          }
          this.$store.dispatch('document/tryStreamPDF', payload).then((streamData) => {
            this.streamPDFRequestController = null
            const file = new Blob([streamData], { type: 'application/pdf' })
            this.streamPDFData = URL.createObjectURL(file)
            this.isPdfLoading = false
          })
        }

        this.isLoading = false
        this.pendingSignatureZonesArray = this.document.pendingSignatureDocumentZones
        const pendingZonesPerUser = this.pendingSignatureZonesArray.filter(a => String(a.signatureUserID) === String(this.UserID) && a.roleName !== 'Viewer' && a.roleName !== 'Reviewer')
        this.totalSignatureZones = pendingZonesPerUser.length
        this.$store.dispatch('document/setPendingSignatureAnnotationIDsForCurrentUser', this.document.pendingSignatureAnnotationIDsForCurrentUser)
        this.$store.dispatch('document/setPendingSignatureDocumentZones', this.document.pendingSignatureDocumentZones)
        this.pages = [this.document.totalPages]
        this.hasPendingSignatureZones = this.pendingSignatureZonesArray && this.pendingSignatureZonesArray.some(a => String(a.signatureUserID) === String(this.UserID) && a.roleName !== 'Viewer' && a.roleName !== 'Reviewer')
        this.isReviewer = this.pendingSignatureZonesArray && this.pendingSignatureZonesArray.some(a => String(a.signatureUserID) === String(this.UserID) && a.roleName === 'Reviewer')
        this.isViewer = this.pendingSignatureZonesArray && this.pendingSignatureZonesArray.some(a => String(a.signatureUserID) === String(this.UserID) && a.roleName === 'Viewer')
        const pendingSignatureZones = this.pendingSignatureZonesArray.filter(a => String(a.signatureUserID) === String(this.UserID))
        pendingSignatureZones.filter(p => {
          if(!this.pendingSignaturesPageNumbers.find(a => a === p.pageNumber)) {
            this.pendingSignaturesPageNumbers.push(p.pageNumber)
          }
        })
        this.hasMoreThanOnePendingSignatureZone = this.pendingSignaturesPageNumbers.length > 1
        if (data.Tasks) {
          this.tasks = data.Tasks && data.Tasks.Values ? data.Tasks.Values : []
        }

        if (data.Workflow) {
          this.workflow = data.Workflow
          this.buttonOptions = data.Workflow.Actions && data.Workflow.Actions.Values ? data.Workflow.Actions.Values : []
          this.workflowComments = data.Workflow.Comments && data.Workflow.Comments.Values ? data.Workflow.Comments.Values : []
          this.workflowID = data.Workflow.WorkflowId

          const payload = {
            DocumentID: this.documentID,
            WorkflowID: data.Workflow.WorkflowId,
          }

          this.$store.dispatch('document/getDocumentDueDate', payload).then((newDueDate) => {
            // load the due date from the server - it's possible it's different than what's on the document index field
            this.dueDate = newDueDate
          })

          if (data.IndexFormFields && data.IndexFormFields.Values && data.IndexFormFields.Values.length > 0) {
            const workflowDueDateField = data.IndexFormFields.Values.find(field => field.FieldName === 'Workflow_Due_Date')
            if (workflowDueDateField) {
              this.dueDateLabel = workflowDueDateField.DisplayName
            }
          }

          if (this.workflow.PasswordProtected) {
            this.actionPasswordRequired = true
          }
        }

        for (let i = 0; i < this.totalPages; i += 1) {
          this.pages[i] = {
            pageNumber: (i + 1),
            imageSource: '',
          }
        }

        const foundForm = this.attributeForms.find((t) => t.value === this.documentAttributeFormID)
        if (foundForm) {
          this.currentAttributeFormID = foundForm
        }
        if (this.$route.params.startingPage) {
          this.currentPageNumber = parseInt(this.$route.params.startingPage, 10)
        } else {
          this.currentPageNumber = 1
        }
        this.loadThumbnails()
      })
    },
    async reviewedSignatureDocument() {
      this.isLoading = true

      const payload = {
        documentId: this.documentID,
      }

      await Promise.all([
        this.$store.dispatch('document/approveSignatureDocument', payload),
        this.saveSignatureDocumentHistory('Reviewed'),
        this.saveSignatureDocumentComment(),
      ])
      this.isLoading = false

      if (this.isSignatureDocLinkView) {
        this.$router.push({
          name: 'TheSignatureConfirmation',
          params: {
            confirmationMessage: 'Document Reviewed',
            confirmationSubMessage: 'Thank you for using Vasion',
          },
        })
      } else {
        this.backToPreviousView()
      }
    },
    rotate(direction) {
      const rightOrLeft = direction === 'right' ? 90 : -90
      this.currentPageRotation += rightOrLeft
      if (Math.abs(this.currentPageRotation) == 360) {
        this.currentPageRotation = 0
      }
      const imgContainer = document.getElementsByClassName('rotateMeImage')[0]
      const img = imgContainer.childNodes[1];
      img.style.transform = `rotate(${this.currentPageRotation}deg)` // this rotates the main document
      if (imgContainer.childNodes[2])
      {
        const annotations = imgContainer.childNodes[2].firstChild;
        annotations.style.transform = `rotate(${this.currentPageRotation}deg)` // this rotates the main document's annotations
      }
      if(document.getElementsByClassName('thumbnailImageSelected')[0])
      {
        const activeThumbnailContainer = document.getElementsByClassName('thumbnailImageSelected')[0].firstChild.firstChild
        const activeThumbnail = activeThumbnailContainer.childNodes[1];
        activeThumbnail.style.transform = `rotate(${this.currentPageRotation}deg)` // this rotates the selected document's thumbnail
        const activeThumbnailAnnotations = activeThumbnailContainer.childNodes[2].firstChild
      activeThumbnailAnnotations.style.transform = `rotate(${this.currentPageRotation}deg)` // this rotates the selected document's thumbnail's annotations
      }
    },
    rotateLeft() { this.rotate('left') },
    rotateRight() { this.rotate('right') },
    async nextDocument() {
      this.isLoading = true
      let nextDocumentId = this.additionalDocumentIds[0]
      let remainingDocuments = this.additionalDocumentIds.shift()
      remainingDocuments = this.additionalDocumentIds.join(',')
      this.additionalDocumentIds.length === 0 ? this.$router.replace({ path: `/document/${nextDocumentId}`}) :
        this.$router.replace({ name: 'DocumentMultiple', params: { documentID: nextDocumentId, additionalDocumentIds: remainingDocuments } })
      this.isLoading = false
      this.showNextDocumentSnackbar(true, 'Document')
    },
    async saveAllTypes(hideUI, reloadDocument, nextDocument = false) {
      if (!hideUI) {
        this.isLoading = true
      }
      // we want to save the attribute data *before* the workflow things are saved
      const attributeSuccess = await this.saveAttributeData()
      const wfSaveSuccess = await Promise.all([this.saveDueDate(), this.savePriority()])

      const saveSuccess = attributeSuccess && !wfSaveSuccess.includes(false)

      if (reloadDocument && !nextDocument) {
        this.reloadDocument(this.documentID)
      }
      else if (reloadDocument && nextDocument) {
        this.nextDocument()
      }

      if (!hideUI) {
        this.isLoading = false
        this.showSavedSnackbar(saveSuccess, 'Document Attributes')
      }
    },
    async saveAttributeData() {
      if (!this.currentAttributeFormID || this.currentAttributeFormID.value <= 0) {
        return true
      }
      if (this.isDirtyFromTheDocumentData) {
        const saveResult = await this.$refs.documentData.saveAttributeData()
        if (saveResult) {
          this.dirtyAttributeState = false
          this.isDirtyFromTheDocumentData = false
        }

        if (this.viewMode === 'data') {
          return saveResult
        }
      }

      const payload = {
        DocumentId: this.documentID,
        FormId: this.currentAttributeFormID.value,
        FieldValues: {
          Values: [],
        },
      }

      // first build up the fields that changed
      if (this.localFieldValues) {
        this.localFieldValues.forEach((localValue) => {
          payload.FieldValues.Values.push({
            Key: localValue.name,
            Value: this.getStringFromIndexValue(localValue.value),
          })
        })
      }

      if (this.currentAttributeFormID && this.currentAttributeFormID.value === this.documentAttributeFormID) {
        // we're not changing the attribute form, so check to see if anything changed
        if (payload.FieldValues.Values.length === 0) {
          return true // nothing changed, so we're done!
        }
      }

      // now add anything to the list that was previously on the document
      this.documentAttributeFields.forEach((docField) => {
        const foundField = this.localFieldValues.find((local) => {
          return local.name === docField.FieldName
        })

        if (!foundField) {
          payload.FieldValues.Values.push({
            Key: docField.FieldName,
            Value: this.getStringFromIndexValue(this.getValueFromDocField(docField)),
          })
        }
      })

      const result = await this.$store.dispatch('document/saveDocumentAttributes', payload)
      const saveSuccess = !!result

      if (saveSuccess) {
        this.dirtyAttributeState = false
        this.localFieldValues = [] // can remove any locally modified fields because they've been saved
      }

      return saveSuccess
    },
    async saveSignatureDocumentHistory(actionTaken) {
      const payload = {
        documentID: this.documentID,
        UserType: 'Signer',
        geolocation: this.geolocation ? this.geolocation : '',
        ipAddress: this.ipAddress ? this.ipAddress : '',
        actionTaken: actionTaken,
        comment: actionTaken === 'Comment' && this.signatureComment ? this.signatureComment : '',
      }

      return await this.$store.dispatch('document/addSignatureDocumentHistory', payload)
    },
    async saveDueDate() {
      if (!this.dirtyDueDateState || !this.workflowID) { return true }

      const payload = {
        documentID: this.documentID,
        workflowID: this.workflowID,
        dueDate: this.dueDate,
      }
      const result = await this.$store.dispatch('workflow/saveWorkflowDueDate', payload)
      const saveSuccess = result.Value === 'True'

      if (saveSuccess) {
        this.dirtyDueDateState = false
      }

      return saveSuccess
    },
    async saveSignatureDocumentComment() {
      if (!this.signatureComment) {
        return
      }

      const payload = {
        DocumentId: this.documentID,
        NoteText: this.signatureComment,
        PageNumber: this.currentPageNumber,
      }

      await Promise.all([
        this.$store.dispatch('document/saveNote', payload),
        this.saveSignatureDocumentHistory('Comment'),
      ])
    },
    async savePriority() {
      if (!this.dirtyPriorityState) { return true }
      const payload = {
        documentID: this.documentID,
        priorityID: this.priorityValue,
      }
      const result = await this.$store.dispatch('document/savePriority', payload)
      const saveSuccess = result.Value === 'True'
      if (result.Value === 'True') {
        this.dirtyPriorityState = false
      }

      return saveSuccess
    },
    async saveRotate() {
      let pageRotation = this.currentPageRotation >= 0 ? this.currentPageRotation : 360 + this.currentPageRotation
      const payload = {
        DocumentId: this.documentID,
        PageNumber: this.currentPageNumber,
        RotationDegrees: pageRotation,
      }
      const result = await this.$store.dispatch('document/rotateDocument', payload)
      if(result.Value) {
        this.showSnackbar = true
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = 'Unable to Save Rotate'
        this.snackbarImage = false
      }
      else {
        this.reloadDocument(this.documentID)
      }
    },
    setActionFields(values) {
      this.actionsFieldList = values.map(v => {
        const dropValues = []
        const dropSelectedValues = []
        if (v.DropDownValues) {
          v.DropDownValues.Values.forEach((d, i) => {
            dropValues.push({
              name: d,
              value: i,
            })
          })
        }
        if (v.DropDownSelectedValues) {
          v.DropDownSelectedValues.Values.forEach(d => {
            dropSelectedValues.push(d)
          })
        }
        let filterValue = v.DateValueUTC ? v.DateValueUTC : v.TextValue
        if (!filterValue) {
          filterValue = dropSelectedValues
        }
        return {
          dropdownValues: dropValues,
          fieldID: v.FieldID,
          filterValue,
          hidden: v.Hidden,
          name: v.DisplayName,
          readOnly: v.ReadOnly,
          type: v.Control,
          value: v.FieldName,
          required: v.Required,
        }
      })
    },
    setActionPassword(value) {
      if (value) {
        this.actionPassword = value
      }
      this.workflowAction(this.currentWorkflowAction)
    },
    setDropdownLocation(x, y, offsetX, offsetY) {
      const bonusWhiteSpaceY = this.showEllipsisToolbar ? 15 : 25
      const leftNavWidthOffset = window.innerWidth > 950 ? 254 : 0 // 950 is the viewport width that the left side nav hides itself
      let newX = x - offsetX - leftNavWidthOffset
      let newY = y - offsetY - bonusWhiteSpaceY
      this.dropdownLocation = `top: ${newY}px; left: ${newX}px;`
    },
    setDueDate(event) {
      this.dueDate = event
    },
    setFormName() { this.formName = this.attributeForms.find((t) => t.value === this.currentAttributeFormID.value).name },
    setWorkflowAction(action) {
      this.currentWorkflowAction = action
      if (this.actionPasswordRequired) {
        this.showActionPasswordDialog = true
      } else {
        this.workflowAction(action)
      }
    },
    async setZoom() {
      await this.$store.dispatch('document/resetZoomValue')
      if (window.innerWidth <= 416) {
        this.$store.dispatch('document/setZoomValue', .3)
      } else if (window.innerWidth <= 1430) {
        this.$store.dispatch('document/setZoomValue', .512)
      } else if (window.innerWidth <= 1630) {
        this.$store.dispatch('document/setZoomValue', .64)
      } else if (window.innerWidth <= 1870) {
        this.$store.dispatch('document/setZoomValue', .8)
      }
    },
    showCertificate() {
      // TODO: Implement this method to display the certificate on button click
    },
    showEmailSnackbar(value) {
      this.showSnackbar = true
      this.snackbarTitle = value[0] ? 'SUCCESS' : 'ERROR'
      this.snackbarSubTitle = value[0] ? 'Email Sent!' : 'Unable to Send Email'
      // eslint-disable-next-line prefer-destructuring
      this.snackbarImage = value[0]
    },
    showRecallSnackbar(value) {
      this.showSnackbar = true
      this.snackbarTitle = value ? 'SUCCESS' : 'ERROR'
      this.snackbarSubTitle = value ? 'Document Recalled' : 'Unable to Recall'
      this.snackbarImage = value
    },
    showRejectSnackbar(value) {
      this.showSnackbar = true
      this.snackbarTitle = value ? 'SUCCESS' : 'ERROR'
      this.snackbarSubTitle = value ? 'Document Rejected' : 'Unable to Reject'
      this.snackbarImage = value
    },
    showSavedSnackbar(value, type) {
      this.showSnackbar = true
      this.snackbarTitle = value ? 'SUCCESS' : 'ERROR'
      this.snackbarSubTitle = value ? `${type} Saved!` : `Unable to Save ${type}`
      this.snackbarImage = value
    },
    showNextDocumentSnackbar(value, type){
      this.showSnackbar = true
      this.snackbarTitle = value ? 'SUCCESS' : 'ERROR'
      this.snackbarSubTitle = value ? `${type} Loading!` : `Unable to Load ${type}`
      this.snackbarImage = value
    },
    async showHistory() {
      this.signatureTabSelected = this.isInSignatureMode
      this.$store.dispatch('document/getDocumentHistory', this.documentID)
      this.signatureDocumentHistoryValues = await this.$store.dispatch('document/getSignatureDocumentHistory', this.documentID)
      this.historyMode = 'history'
      this.showHistoryDialog = !this.showHistoryDialog
    },
    showUploadFailure() {
      this.showSnackbar = true
      this.snackbarTitle = 'ERROR'
      this.snackbarSubTitle = 'Pages could not be saved!'
      this.snackbarImage = false
    },
    async signatureCommentButtonClicked() {
      const response = await this.saveSignatureDocumentHistory('Comment')
      this.signatureComment = ''

      if (response) {
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Comment saved successfully!'
        this.snackbarImage = true
        this.showSnackbar = true
      } else {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Something went wrong while trying to save your comment.'
        this.snackbarImage = false
        this.showSnackbar = true
      }
    },
    async startWorkflowForSelectedDocuments() {
      this.showErrorLabel = false
      const docID = this.documentID.toString()
      const workflowID = this.selectedWorkflowID
      if (Object.keys(this.startWorkflowPayload).length === 0) {
        this.startWorkflowPayload = {
          documentId: docID,
          workflowId: workflowID,
          userId: parseInt(this.UserID, 10),
        }
      }
      const response = await this.$store.dispatch('workflow/attemptAssignToWorkflowWithData', this.startWorkflowPayload)
      if (response === null) {
        this.showWorkflowList = false
        this.showUserSelectModal = false
        this.showSnackbarBool = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Workflow has been started'
        this.snackbarImage = true
        if (this.hasAdditionalDocumentIds){
           setTimeout(() => {
            this.nextDocument()
          }, 2000)
        }
        else {
          setTimeout(() => {
            this.backToPreviousView()
          }, 2000)
        }

      } else if (response?.userAssignPayload.retPromptKey === 'prmt_ChooseFromGroup') {
        this.userGroupAssignConfig.groupId = response.assignUserGroupFilterId
        this.startWorkflowPayload = response.userAssignPayload
        this.showWorkflowList = false
        this.showUserSelectModal = true
      } else {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'There was an error starting the Workflow'
        this.snackbarImage = false
      }
    },
    toggleBurnAnnotations() {
      this.showBurnAnnotations = !this.showBurnAnnotations
    },
    toggleRejectSignature() {
      if (this.signatureComment === '' && !this.showRejectSignatureModal) {
        this.missingCommentForReject = true
      } else if (this.signatureComment !== '' && !this.showRejectSignatureModal) {
        this.showRejectSignatureModal = true
      } else {
        this.showRejectSignatureModal = false
      }
    },
    toggleRotateActions(event) {
      this.setDropdownLocation(event.pageX, event.pageY, event.offsetX, event.offsetY)
      this.showRotateActions = !this.showRotateActions
    },
    toggleShowAddSignatureDialog() {
      if (!this.isInSignatureMode) {
        this.addInitialsDropListActive = !this.addInitialsDropListActive
      }
      this.showAddSignatureDialog = !this.showAddSignatureDialog
    },
    toggleShowAddSignatureList(event) {
      if (!event) { event = this.signatureIconClickEvent }
      this.addStampsDropListActive = false
      this.setDropdownLocation(event.pageX, event.pageY, event.offsetX, event.offsetY)
      this.addInitialsDropListActive = !this.addInitialsDropListActive
    },
    toggleShowAddStampList(event) {
      this.addInitialsDropListActive = false
      this.showAddSignatureDialog = false

      this.setDropdownLocation(event.pageX, event.pageY, event.offsetX, event.offsetY)
      this.addStampsDropListActive = !this.addStampsDropListActive
    },
    async toggleStartWorkflow() {
      if (!this.currentAttributeFormID || this.currentAttributeFormID.value === undefined || this.currentAttributeFormID.value <= 0) {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = `Please select an ${$formsLabel}.`
        this.snackbarImage = false
        this.showSnackbar = true
        return
      }
      const payload = {
        includeStartFromEform: false,
        includeScan: true,
        includeBrowse: true,
        includeUpload: true,
        formID: this.currentAttributeFormID?.value,
      }

      this.workflowList = await this.$store.dispatch('workflow/getWorkflowMenuItemsFiltered', payload)
      if (!this.workflowList || this.workflowList.length <= 0) {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = `No Workflows Configured for the ${$formsLabel}`
        this.snackbarImage = false
        this.showSnackbar = true
        return
      }

      this.showWorkflowList = true
    },
    toggleUploadAndPage() {
      this.showUploadNewPageModal = !this.showUploadNewPageModal;
      this.showAddPagesModal = !this.showAddPagesModal;
    },
    toggleVersionsView() { this.showVersions = !this.showVersions },
    toggleViewClick() {
      if (!this.document.LocalPDFName) {
        // only toggle between Document and Data View
        this.viewMode = this.viewMode === this.viewMode_Data ? this.viewMode_Document : this.viewMode_Data
      } else {
        this.showViewModeMenu = !this.showViewModeMenu
      }
    },
    updateLocalFieldValues(childComponentValues) { this.localFieldValues = childComponentValues },
    updateLookupValues(item) {
      this.showMultipleLookupDialog = false
      this.$refs.attributeFieldsComponent.updateFieldsFromLookup(item)
      this.dirtyAttributeState = true
    },
    updateSelectedPages(selectedList) {
      this.selectedPagesForDelete = selectedList
      if (this.selectedPagesForDelete.length > 0) {
        this.disableDeletePagesButton = false
      } else {
        this.disableDeletePagesButton = true
      }
    },
    updateTasks(newTaskList) { this.tasks = newTaskList },
    async uploadDocument() { await this.$store.dispatch('document/uploadNewPages') },
    uploadNewPageToggle(index) {
      this.uploadIndex = index
      this.showAddPagesModal = !this.showAddPagesModal
      this.showUploadNewPageModal = !this.showUploadNewPageModal
    },
    uploadPage(newPage) { this.$store.dispatch('document/insertReplacePages', newPage) },
    versionSelect(payload) { this.document = payload.data },
    viewMenuSelectionMade(payload) {
      this.closeViewModeMenu()
      if (!payload || !payload.buttonId || payload.buttonId === 'close') {
        return
      }

      this.viewMode = payload.buttonId
    },
    async workflowAction(action) {
      this.isLoading = true
      await this.saveAllTypes(true, false)

      this.action = action
      const payload = {
        CheckListComplete: true,
        UniqueName: action,
        DocumentId: this.documentID,
        Password: this.actionPassword,
        Comment: this.newComment,
        PromptData: this.promptData,
        StepID: this.$route.params.workflowStepId,
      }

      const response = await this.$store.dispatch('workflow/doWorkflowAction', payload)
      this.isLoading = false
      if (response && response.StepComplete && response.PromptName !== 'prmt_Open_URL') {
        this.showSnackbar = true
        this.snackbarTitle = 'SUCCESS'
        this.snackbarSubTitle = `${action} successful`
        this.snackbarImage = true
        this.$router.push({ name: 'TheWorkspaceHome' })
        this.showActionPasswordDialog = false
      } else if (response?.PromptName === 'prmt_ChooseGroupUser' || response?.PromptName === 'prmt_ChooseFromGroup') {
        // prmt_ChooseGroupUser = Need to Reassign or Forward
        // prmt_ChooseFromGroup = Need to assign to a user within a specific group
        this.showActionPasswordDialog = false
        this.userGroupAssignConfig.type = response?.PromptName
        if (this.userGroupAssignConfig.type === 'prmt_ChooseFromGroup') {
          this.userAssignPayload = response?.PromptData
          this.userGroupAssignConfig.userOnly = true
          this.modalTitle = 'Select User'
        } else {
          this.modalTitle = this.action
        }

        const userList = response.UserList && response.UserList.Values && response.UserList.Values.length > 0
                ? response.UserList.Values
                : []
        const groupList = response.GroupList && response.GroupList.Values && response.GroupList.Values.length > 0
                ? response.UserList.Values
                : []
        const dataList = await this.createUserAndGroupData(userList, groupList)
        this.$store.dispatch('common/setGroupAndUsers', dataList)

        this.showGroupModal = true;
      } else if (response?.PromptName === 'prmt_IndexDocument') {
        this.indexPromptName = response?.PromptName
        this.setActionFields(response.FieldList.Values)
        this.showActionPasswordDialog = false
        this.showIndexPrompt = true
      } else if (!response?.StepComplete && response?.PromptName === 'prmt_Password') {
        this.showSnackbar = true
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = 'Invalid Password'
        this.snackbarImage = false
        this.$refs.passwordPrompt.setErrorClass()
      } else if (response?.PromptName === 'prmt_Note') {
        this.showSnackbar = true
        this.snackbarTitle = 'Unable to continue'
        this.snackbarSubTitle = 'In order to complete this step, a Workflow Comment is required.'
        this.snackbarImage = false
      } else if (response?.PromptName === 'prmt_Complete_Tasks') {
        this.showSnackbar = true
        this.snackbarTitle = 'Unable to continue'
        this.snackbarSubTitle = 'Please complete the list of tasks in order to complete the step.'
        this.snackbarImage = false
      } else if (response?.PromptName === 'prmt_Open_URL') {
        let url = response?.PromptData?.Values[0]?.Value?.Values[0]?.Value
        if (url.length > 0) {
          if (!url.startsWith('http')) {
            url = `http://${url}`
          }
          window.open(url, '_blank')
        } else {
          this.showSnackbar = true
          this.snackbarTitle = 'Unable to continue'
          this.snackbarSubTitle = 'Tried to open URL, but URL was blank.'
          this.snackbarImage = false
        }
      } else {
        this.showSnackbar = true
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = response && response.Message ? response.Message : `${action} failed`
        this.snackbarImage = false
      }
    },
    zoomIn() {
      if (!this.disableZoomIn) {
        this.$store.dispatch('document/setZoomValue', true)
      }
    },
    zoomOut() {
      if (!this.disableZoomOut) {
        this.$store.dispatch('document/setZoomValue', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .no-objects-warning {
    margin: 7px;
    padding: 8px 16px;
    border-left: 5px solid #F1C40F;
    background-color: #fff3c3;
    color: #cca610;
  }

  .md-layout {
    flex-wrap: nowrap !important;
  }

  .file-pond-block-wrapper {
    width: 400px;
    padding: 0;
  }

  .error-back-div {
    margin-top: 24px;
  }

  .error-div {
    padding: 48px 12px 0px 12px;
    width: 100%;
    text-align: center;
  }

  .error-state-container {
    border: 1px solid $error-red !important;
  }

  .vasion-ellipsis-menu {
    fill: $grey-500;
    margin-top: -140px;
  }

  .comment-textarea{
    margin: 6px 8px;
    width: 95%;
    padding: 10px;
    height: 124px;
    z-index: 5;
  }

  .comment-textarea:focus{
    outline: none;
  }

  .bottom-margin {
    margin-bottom: 10px;
  }
  .circle-icon-color {
    fill: #838587;
    position: absolute;
    left: 242px;
  }
  .document-container {
    height: calc(100vh - 56px);
    overflow: hidden;
  }
  .custom-actions {
    overflow: auto;
    padding: 0px 25px 25px 25px;
    height: 100%;
  }
  #customActionsDialog {
    width: 50%;
    max-width: 80%;
    max-height: 80%;
  }
  .dropDownOpen {
    min-height: 50%;
  }
  .data-view-container {
    display: flex;
    justify-content: center;
    overflow: auto;
    height: calc(100vh - 160px);
    padding: 0 16px 16px 16px;
  }
  .data-view {
    width: 90%;
    height: calc(100vh - 195px);
    overflow: auto;
    background-color: white;
    margin-top: 16px;
    box-shadow: 0px 7px 7px 0px $shadow;
    border-radius: 8px;
  }
  .data-view-header {
    margin: 0;
    padding: 33px 0px 31px 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: $grey-50;
  }
  .document-title {
    font-family: $font-family-bold;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey-500;
    text-align: left;
    padding-top: 5px
  }
  .double-icon {
    width: 40px;
    .md-ripple {
      border-radius: 3px;
    }
  }
  .double-icon .md-ripple {
      border-radius: 3px;
  }
  .padding-all {
    padding: 0 20px;
  }
  .index-field-list-padding {
    padding: 0 20px 15px;
  }
  .action-links {
    width: 64px;
    height: calc(100vh - 161px);
    display: flex;
    flex-direction: column;

    .action-link {
      margin: 13px 17px;
      cursor: pointer;
      z-index: 3;
    }
  }

  #show-sidebar-button {
    display: none;
  }

  .mainThumbnails {
    width: 156px;
    overflow-y: auto;
    height: calc(100vh - 161px);
  }

  .headerDiv {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }
  .alignCenter {
    display: flex;
    align-items: center;
  }

  .index-field-title {
    padding: 25px;
  }

  .clickableIcon:hover {
    cursor: pointer;
  }
  .rightSideButton {
    width: 95%;
  }
  .rotateCenter {
    padding-left: 4px;
    padding-top: 3px;
  }

  .signature-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .flex-display {
    display: flex;
  }

  .stamp-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  #image-div {
    width: 0;
  }
  .mainImageDiv {
    padding: 16px;
    overflow: auto;
    height: calc(100vh - 162px);
    text-align: center;
  }
  @media (max-width: $phone) {
    .mainImageDiv {
      padding: 20px;
    }
  }
  .required-fields {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .zones-counter {
    margin-left: 5px;
    margin-right: 5px;
    width: 48px;
    height: 24px;
    opacity: .7;
    border-radius: 12px;
    background-color: #e6f0fc;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mainImage {
    width: 100%;
    height: 100%;
  }

  .thumbnailDiv {
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 8px;
    padding-right: 16px;
  }

  .extra-padding-bottom {
    padding-bottom: 12px;
  }

  .thumbnailImage {
    border: solid;
    border-width: 2px;
    border-color: #f5f8fb;
    height: 166px;
    width: 128px;
  }

  .thumbnailImage:hover{
    border-color: $orange-300;
    opacity: .65;
    cursor: pointer;
  }

  .thumbnailImageSelected {
    border-color: $orange-300;
  }

  .initials-drop-list {
    position: absolute;
    left: 730px;
    top: 106px;
    z-index: 11;
    background-color: white;
    box-shadow: 0 1px 3px 0 $shadow;
    border-radius: 8px;
    ul {
      margin: 0;
      padding-left: 0;
      :hover {
        background-color: $grey-75;
        cursor: pointer;
      }
    }
    li {
      padding: 0 16px;
      width: 280px;
      border-bottom: 1px solid #d4d4d4;
      height: 96px;
      display: flex;
      align-items: center;
    }
    .add-new-signature {
      height: 60px;
      color: $grey-500;
      font-family: $font-family-book;
    }
  }

  .stamps-drop-list {
    position: absolute;
    z-index: 11;
    background-color: white;
    box-shadow: 0 1px 3px 0 $shadow;
    border-radius: 8px;
    ul {
      margin: 0;
      padding-left: 0;
      :hover {
        background-color: $grey-75;
        cursor: pointer;
      }
      max-height: 300px;
      overflow-y: auto;
    }
    li {
      padding: 0 16px;
      width: 280px;
      border-bottom: 1px solid #d4d4d4;
      height: 96px;
      display: flex;
      align-items: center;
    }
    .add-new-stamp {
      height: 60px;
      color: $grey-500;
      font-family: $font-family-book;
    }
  }

  .gesture-gray {
    fill: $grey-400;
    margin-right: 16px;
  }

  .innerDiv {
    padding-top: 10px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .blackBorder {
    border: black;
    border-style: solid;
  }

  .remainingDocumentSection {
    -webkit-flex: 1; /* Chrome */
    flex: 1; /* NEW, */
    overflow: auto;
    background-color: $white;
  }

  .divider {
    width: 1px;
    background-color: $divider-light-grey;
  }

  .alignRight {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .w30 {
    max-width: 40px;
    height: 30px;
  }
  .w15 {
    width: 20px;
    height: 30px;
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  .Rectangle-2020 {
    width: 16px;
    height: 16px;
    background-color: $input-text;
    border-radius: 2px;
  }

  .Rectangle-2020-White {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: solid 1px $input-text;
    background-color: $white;
  }

  .tabDiv {
    background-color: $white;
    border-bottom: solid 1px $divider-light-grey;
    text-align: center;
  }

  .toolbar-div {
    border-bottom: solid 1px $divider-light-grey;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 3px;
    padding-right: 100px;
    width: 100%;
  }

  .lightestGreyBackground {
    background-color: $grey-75;
  }

  .signature-image {
    width: auto;
    max-height: 85px;
  }

  .stamp-image {
    width: auto;
    max-height: 85px;
    max-width: 175px;
  }

  .smallText {
    height: 28px;
    line-height: 20px;
    min-height: 20px;
    font-size: 12px;
    padding: 0 0;
    margin: 0;
    text-transform: none !important;
  }
  .rightText {
    text-align: right;
  }

  .attribute-top-section-with-workflow {
    padding: 15px 15px 15px 15px !important;
    border-top: 2px solid #e4e5e6;
    border-bottom: 2px solid #e4e5e6;
  }

  .attribute-top-section-without-workflow {
    padding: 0px 15px 15px 15px !important;
    border-bottom: 2px solid #e4e5e6;
  }

  #new-comment {
    width: calc(100% - 12px);
    margin: 7px;
  }

  .attribute-droplist {
    margin: 5px;
  }

  .attributes-tab-div, .attributes-tab-div-closed {
    width: 300px;
    max-width: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 161px);
    transition: 0.275s;
  }

  .right-panel-toggle-closed, .right-panel-toggle-open{
    display: none;
  }

  .attributes-padding-div {
    padding: 16px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .attributes-padding-div-buttons {
    width: 100%;
  }

  .alignRight {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .align-text-and-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .email-modal-div {
    overflow: auto;
  }

  .toolbar-button {
    margin: 0 0 5px 0;
  }

  .toolbar-height {
    height: 24px;
  }
  .toolbar-height-not-native-document-view {
    margin-bottom: 10px;
  }
  .toolbar-margin-not-document-view {
    margin-left: 5px;
  }

  .pdfiFrame {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: auto;
    height: calc(100vh - 160px);
    padding: 0 16px 16px 16px;
}

  .pdfiFrame iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #confirmCompleteSignature {
    max-width: 615px;
    margin: 10px 0px 10px 0px;
  }
  .prompt-password-dialog {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .prompt-password-dialog > * {
    margin: 5px 0px;
  }
  .password-label {
    margin-top: 10px;
  }
  .entered-password {
    margin-bottom: 20px;
  }
  .side-panel-expanded {
    background-color: #ffefe6;
    fill: $orange-300 !important;
    color: $orange-300 !important;
  }
  #documentHistory {
    fill: black;
  }
  #history-tab-buttons {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #signature-document-title-container {
    display: flex;
    flex-direction: column;
  }

  .md-field.md-theme-default.md-focused .md-input {
    color: black;
  }
  .md-field.md-theme-default,
  .md-field.md-theme-default:before,
  .md-field.md-theme-default:after { // Remove the weird md-input's underline border
    background: none;
  }
  .toolbar-divider {
    border-left: solid 1px $grey-100;
    height: 24px;
    margin-top: 5px;
    position: absolute;
    margin-left: 10px;
  }
  .toolbar-divider + .toolbar-icon {
    margin-left: 20px;
  }

  #toggle-btn {
    width: 100%;
    position: absolute;
    padding-right: 25px;
  }

  .drop-arrow-icon {
    margin-bottom: 4px;
  }

  .center-flex-content {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media(min-height: 1200px) {
    .data-view {
      height: calc(100vh - 250px)
    }
  }

  @media(max-width: 1410px) { // TODO: Change this to 1280 once the zoom buttons are removed from the toolbar and match zeplin
    #ellipsis-toolbar {
      padding: 5px 5px 15px 5px !important;
      gap: 5px 0;
      border-radius: 8px;
      background: white;
      position: absolute;
      z-index: 100;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-wrap: wrap;
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    }
  }

  @media(max-width: 1170px) { // TODO: Change this to 1280 once the zoom buttons are removed from the toolbar and match zeplin
    .toolbar-div {
      padding-bottom: 15px;
      gap: 15px 0;
    }
  }

  @media(max-width: 875px) {
    #ellipsis-toolbar { // TODO: Move the padding design to @media(max-width: 720px) once the zoom buttons are removed from the toolbar and match zeplin
      padding: 5px !important;
    }
  }

  @media(max-width: 720px) {
    #show-sidebar-button {
    display: block;
    border-radius: 32px;
    margin-right: 45px;
    margin-top: -35px;
    float: right;
    }
    .attributes-padding-div {
      padding: 16px;
    }
    .attributes-tab-div{
      position: absolute;
      right: 0;
      background-color: white;
      border-left: 1px #e1e8ed solid;
      z-index: 10;
    }

    .right-panel-toggle-closed{
      padding-top: 20px;
      display: block;
    }
    .right-panel-toggle-open{
      display: block;
      margin: 20px;
    }
    .attributes-tab-div-closed {
      position: absolute;
      right: 0;
      display: flex;
      justify-content: center;
      width: 0px;
      overflow-y: auto;
      height: calc(100vh - 161px);
      transition: 0.275s;
      background-color: white;
      border-left: 1px #e1e8ed solid;

      .right-side-panel-content{
        display: none;
      }
    }

    .right-panel-toggle-open{
      display: block;
      opacity: 1;
      transform: scaleX(-1);
      margin: 20px 20px 0px 0px;
      position: absolute;
      right: 0;
      background-color: white;

      .right-side-panel-content{
        display: block;
      }
    }
  }

  @media (max-width: 500px) {
    #toolbar-print-upload-email,
    .toolbar-divider,
    #toolbar-add-delete-pages-and-rotate,
    #toolbar-annotations,
    #toolbar-ellipsis {
      display: none;
    }

    .toolbar-div {
      flex-wrap: nowrap;
      padding-right: 4px;
      .toolbar-icon {
        margin-left: 0px;
      }
    }

    .toolbar-div-not-in-document-view {
      padding-bottom: 40px;
    }
  }

  @media (max-width: 460px) {
    #ellipsis-toolbar {
      padding-bottom: 15px !important;
    }
    #toggle-btn {
      padding-right: 0px;
    }
    #show-sidebar-button {
      margin-right: 0px;
      margin-top: 0px;
    }
  }

  @media (max-width: $phone) {
    .file-pond-block-wrapper{
      width: 200px;
    }

  }
</style>
