<template>
  <div id="document-tasks">
    <VasionGeneralModal
      :hideButtons="true"
      :modalType="'slot'"
      :sync="showModal"
      @noButtonClick="showModal = !showModal"
    >
      <div v-if="showTasks">
        <h3>Document Tasks</h3>
        <div v-if="dataAvailable" class="tasksGridDiv">
          <VasionTable
            :headerColumns="dataTable.Columns.Values"
            :tableRows="dataTable.Rows.Values"
            :supportSorting="supportSorting"
            :keepCheckedState="true"
            :hideColumns="['DocumentTaskID']"
            :wrapCells="true"
            @newList="handleCheckChange"
          />
        </div>
        <div v-else class="no-data">
          No tasks available for this document.
        </div>
        <div class="task-buttons-align-right">
          <div class="left-padding" />
          <VasionButton
            id="new-task"
            :isDisabled="!enableButtons"
            :classProp="'secondary'"
            @vasionButtonClicked="toggleShowNewTask()"
          >
            New Task
          </VasionButton>
          <VasionButton
            id="add-task"
            :isDisabled="!enableButtons"
            :classProp="'secondary'"
            @vasionButtonClicked="toggleShowAddTasks()"
          >
            Add Task
          </VasionButton>
          <VasionButton
            id="add-tasklist"
            :isDisabled="!enableButtons"
            :classProp="'secondary'"
            @vasionButtonClicked="toggleShowAddTasklists()"
          >
            Add Tasklist
          </VasionButton>
          <VasionButton
            id="close"
            :classProp="'primary'"
            @vasionButtonClicked="showModal = !showModal"
          >
            Close
          </VasionButton>
        </div>
      </div>
      <div v-if="showNewTask">
        <h3>New Task</h3>
        <div class="task-dialog">
          <div>
            <div class="textarea-title">
              TASK TEXT
            </div>
            <textarea
              id="task-textarea"
              v-model="taskTextBody"
              label="Task"
              class="vasion-textarea"
              placeholder="Enter text..."
              rows="10"
            />
          </div>
        </div>
        <div class="task-buttons-align-right">
          <div class="left-padding" />
          <VasionButton id="show-new-task" :classProp="'secondary'" @vasionButtonClicked="toggleShowNewTask()">
            Cancel
          </VasionButton>
          <VasionButton
            id="new-tasks"
            :isDisabled="taskTextBody===''"
            :classProp="'primary'"
            @vasionButtonClicked="newTask()"
          >
            Save
          </VasionButton>
        </div>
      </div>
      <div v-if="showAddTask" id="add-task">
        <h3>Add Task</h3>
        <div class="tasksGridDiv">
          <VasionInput
            v-model="filterTasksString"
            v-debounce:300ms="filterTasks"
            class="search-tasklist"
            name="filter-tasks"
            placeholder="Filter..."
            inputType="search-gray"
          />
          <ul class="task-list">
            <li
              v-for="(task, index) in filteredTasks"
              :key="index"
              class="task"
              :class="{ 'selected': task.selected}"
              @click="task.selected = !task.selected"
            >
              <VasionCheck
                class="vasion-check"
                :class="{ 'vasion-check' : !task.selected, 'vasion-check-selected' : task.selected }"
              />
              <div class="task-text">
                {{ task.name }}
              </div>
            </li>
          </ul>
        </div>
        <div class="task-buttons-align-right">
          <div class="left-padding" />
          <VasionButton id="show-add-tasks" :classProp="'secondary'" @vasionButtonClicked="toggleShowAddTasks()">
            Cancel
          </VasionButton>
          <VasionButton
            id="add-tasks"
            :isDisabled="!addTasksSelected"
            :classProp="'primary'"
            @vasionButtonClicked="addTasks()"
          >
            Add
          </VasionButton>
        </div>
      </div>
      <div v-if="showAddTaskList">
        <h3>Add Tasklist</h3>
        <div class="tasksGridDiv">
          <VasionInput
            v-model="searchTasklistString"
            class="search-tasklist"
            name="filter-multi-select"
            placeholder="Search Task Lists..."
            inputType="search-gray"
          />
          <div class="vasion-html-table-default task-list">
            <div>
              <ul>
                <li
                  v-for="tasklist in selectedTasklists"
                  :key="tasklist.iID"
                  class="li-row"
                  :class="{ 'selected': tasklist.selected }"
                  @click="selectField(tasklist)"
                >
                  <div title="Add Item">
                    <table>
                      <tr>
                        <td>
                          <div class="row">
                            <VasionFormatPointsIcon class="space-right" />
                            {{ tasklist.sName }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="task-buttons-align-right">
          <div class="left-padding" />
          <VasionButton
            id="cancel"
            :isDisabled="!enableButtons"
            :classProp="'secondary'"
            @vasionButtonClicked="toggleShowAddTasklists"
          >
            Cancel
          </VasionButton>
          <VasionButton
            id="add"
            :isDisabled="!enableButtons"
            :classProp="'primary'"
            @vasionButtonClicked="saveTasklistToDocument()"
          >
            Add
          </VasionButton>
        </div>
      </div>
    </VasionGeneralModal>
  </div>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'TheDocumentTasks',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    documentId: {
      type: Number,
      default: 0,
    },
    supportSorting: {
      type: Boolean,
      default: false,
    },
    tasks: {
      type: Array,
      default: () => [],
    },
    workflow: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      availableAddTasks: [],
      dataTable: '',
      enableAddTaskButton: false,
      enableAddTasklistButton: false,
      enableNewTaskButton: false,
      filteredTasks: [],
      filterTasksString: '',
      searchTasklistString: '',
      showAddTask: false,
      showAddTaskList: false,
      showModal: this.active,
      showNewTask: false,
      showTasks: true,
      taskTextBody: '',
      workflowTaskList: [],
    }
  },
  computed: {
    addTasksSelected() { return this.filteredTasks.some(task => task.selected) },
    dataAvailable() {
       return this.dataTable
        && this.dataTable.Columns
        && this.dataTable.Columns.Values
        && this.dataTable.Rows
        && this.dataTable.Rows.Values
        && this.dataTable.Rows.Values.length > 0
    },
    enableButtons() {
       return this.workflow && this.workflow.InWorkflow
    },
    selectedTasklists: {
      get: function () {
        const input = this.searchTasklistString.toLowerCase()
        if (!input) { return this.workflowTaskList }

        return this.workflowTaskList.filter(item => {
          const target = item.sName.toLowerCase()
          return target.indexOf(input) > -1
        })
      },
      set: function (newValue) {
        this.workflowTaskList = newValue
      },
    },
  },
  watch: {
    active: function () {
      this.showModal = this.active
    },
    showModal: function () {
      if (!this.showModal) {
        this.$emit('close')
      } else {
        this.buildDataTable(this.tasks)
      }
    },
  },
  async created() {
    [this.workflowTaskList, this.availableAddTasks] = await Promise.all([
      this.$store.dispatch('workflow/getAllTaskLists'),
      this.$store.dispatch('workflow/getAllTemplateTasks'),
    ])
    this.availableAddTasks = this.availableAddTasks.map((task) => {
      return {
        name: task.sName,
        id: task.iID,
        selected: false,
      }
    })

    this.filteredTasks = this.availableAddTasks
  },
  methods: {
    async addTasklist() {
      this.showAddTaskList = !this.showAddTaskList
    },
    async addTasks() {
      let selectedTasks = this.filteredTasks.filter(task => task.selected)
      selectedTasks = selectedTasks.map(task => task.id)
      const payload = {
        documentId: this.documentId,
        TemplateTaskIds: selectedTasks,
      }
      const response = await this.$store.dispatch('workflow/addAllTemplateTasksToDoc', payload)
      const tasks = response.Tasks.Values
      this.buildDataTable(tasks)
      this.$emit('tasks-updated', tasks)
      this.toggleShowAddTasks()
    },
    buildDataTable(tasks) {
      const data = {
        Columns: {
          Values: [
              'DocumentTaskID',
              'Task',
              'Assigned',
              'Completed',
              '_VasionCheckBox_',
          ],
          ValueType: 'String',
        },
        Rows: {
          Values: [],
          ValueType: 'ApiArray`1',
        },
      }

      data.Rows.Values = tasks.map((task) => {
        const assignedOn = (task.dtAssignedOn) ? format(Date.parse(task.dtAssignedOn), 'MM/dd/yyyy') : ''
        const completedOn = (task.dtCompletedOn) ? format(Date.parse(task.dtCompletedOn), 'MM/dd/yyyy') : ''
        return {
          Values: [
            task.iDocumentTaskID,
            task.sTaskText,
            (task.sAssignedByDisplay) ? `${task.sAssignedByDisplay}\r\n${assignedOn}` : '',
            (task.sCompletedByDisplay) ? `${task.sCompletedByDisplay}\r\n${completedOn}` : '',
            { show: true, value: task.bCompleted },
          ],
          ValueType: 'String',
        }
      })

      this.dataTable = data
    },
    filterTasks(input) {
      this.filteredTasks = this.availableAddTasks.filter((task) => {
        return (task.name.toLowerCase().includes(input) || task.selected)
      })
    },
    async handleCheckChange(newList, lineItem) {
      const payload = {
        DocumentId: this.documentId,
        DocumentTaskId: lineItem.Values[0],
      }
      const data = await this.$store.dispatch('document/completeTask', payload)
      const tasks = data.Tasks?.Values ? data.Tasks.Values : []
      this.buildDataTable(tasks)
      this.$emit('tasks-updated', tasks)
    },
    async newTask() {
      const payload = {
        DocumentId: this.documentId,
        TaskText: this.taskTextBody,
      }
      const data = await this.$store.dispatch('document/addTaskToDoc', payload)

      if (data) {
        this.toggleShowNewTask()
        const tasks = data.Tasks && data.Tasks.Values ? data.Tasks.Values : []
        this.buildDataTable(tasks)
        this.$emit('tasks-updated', tasks)
      }
    },
    async saveTasklistToDocument() {
      const tasklistsIds = []
      this.selectedTasklists.forEach(e => {
        if (e.selected) {
          tasklistsIds.push(e.iID)
        }
      })
      const payload = {
        DocumentId: this.documentId,
        TaskListIds: tasklistsIds,
      }
      const dataList = await this.$store.dispatch('document/addTaskListsToDocument', payload)
      if (dataList) {
        this.dataTable = ''
        this.buildDataTable(dataList)
      }
      this.searchTasklistString = ''
      this.toggleShowAddTasklists()
      this.$emit('tasks-updated', dataList)
    },
    selectField(attributeField) {
      this.selectedTasklists = this.workflowTaskList.map((element) => {
        if (element.iID === attributeField.iID) {
         element.selected = element.selected ? undefined : true
        }
        return element
      })
    },
    selectTasklist(select) {
      this.selectedTasklists.push(select)
    },
    toggleShowAddTasklists() {
      this.showAddTaskList = !this.showAddTaskList
      this.showTasks = !this.showTasks
    },
    toggleShowAddTasks() {
      this.filteredTasks = this.filteredTasks.map((task) => {
        task.selected = false
        return task
      })
      this.showAddTask = !this.showAddTask
      this.showTasks = !this.showTasks
    },
    toggleShowNewTask() {
      this.showNewTask = !this.showNewTask
      this.showTasks = !this.showTasks
      this.taskTextBody = ''
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #tasksModal {
    min-width: 800px;
    width: calc(80vw - 190px);
    padding: 20px;

    #line-item-list {
      width: 360px;
    }

    .no-data {
      padding: 20px;
    }
  }

  .tasksGridDiv {
    padding: 0px 10px 0px 10px;
    height: 60vh;
    width: 900px;
    overflow-x: auto;
  }

  .task{
    display: flex;
    height: 40px;
    align-items: center;
    padding: 5px 0px 5px 5px;
    margin-bottom: 2px;
    white-space:normal;
  }
  .task:hover{
    background-color: $grey-75;
    border-radius: 8px;
    cursor: pointer;
  }
  .task-text{
    padding-left: 10px;
    max-width: 500px;
    overflow: hidden;
  }
  .vasion-check{
    fill: $grey-400;
  }
  .vasion-check-selected{
    fill: $orange-400;
  }

  @media(max-width: $tablet){
    .tasksGridDiv {
      width: 86vw;
    }
  }

  .task-buttons-align-right {
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }

  .task-list {
    max-height: 50vh;
    overflow: auto;
  }

  .row {
    padding-left: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .left-padding {
    width: 70%;
  }

  .selected{
    background-color: $orange-50;
    color: $orange-400;
    fill: $orange-400;
  }
  .space-right {
    margin-right: 10px;
  }
  .search-tasklist {
    width: 100%;
    padding-left: 10px;
    margin-bottom: 15px;
  }
  .li-row {
    margin-bottom: 5px;
  }

  .task-dialog {
    width: 60vh;
    border-radius: 8px;
    padding: 20px 0px;
  }
</style>,
